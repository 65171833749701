import React from "react";
import bub1 from "../assets/bubs/Anbefal restaurant.png";
import bub2 from "../assets/icons_new/Bliv en del af netværket.png";
import bub3 from "../assets/bubs/Bobbel under headerbilledet.png";
import bub4 from "../assets/bubs/CO2 beregning .png";
import bub5 from "../assets/bubs/CO2 offset metode - estimater fra det virkelige liv.png";
import bub6 from "../assets/bubs/FAQ 1..png";
import bub7 from "../assets/bubs/FAQ bund.png";
import bub8 from "../assets/bubs/Forbedrings foreslag.png";
import bub9 from "../assets/bubs/Handelsbetingeser bund.png";
import bub10 from "../assets/icons_new/Manifest.png";

const bubs = [bub1, bub2, bub3, bub4, bub5, bub6, bub7, bub8, bub9, bub10];

export default function Bubble({ variant = 0, height = "initial" }) {
  return <img src={bubs[variant]} alt="" height={height} />;
}
