import React from "react";

export default function Spacer({ value = 0 }) {
  let spacing;
  switch (value) {
    case 0:
      spacing = 10;
      break;
    case 1:
      spacing = 20;
      break;
    case 2:
      spacing = 30;
      break;
    case 3:
      spacing = 40;
      break;
    case 4:
      spacing = 50;
      break;
    case 5:
      spacing = 60;
      break;
    case 6:
      spacing = 70;
      break;
    default:
      spacing = 10;
      break;
  }

  return <div style={{ height: spacing }} />;
}
