import React from "react";
import { Typography, Button, Grid } from "@material-ui/core";
import pic4 from "../assets/icons/vores_strat_qoc1vk.jpg";
import Spacer from "../components/Layout/Spacer";
import TwoColumnGrid from "../components/Layout/TwoColumnGrid";

import treeAndSunset from "../assets/icons/foerste_billede_strategi_xskkq5.png";
import pic6 from "../assets/icons/Vores_strategi_-_Vi_gør_en_forskel.png";
import { Link } from "react-router-dom";
import useTranslation from "../hooks/useT";

export default function Strategi() {
  const t = useTranslation();
  return (
    <div>
      <div className="HomeImg">
        <img src={pic4} alt="" />
        <div className="layer" />
        <Typography variant="h3" gutterBottom>
          {t.eyTitle}
        </Typography>
        <Typography variant="h5" className="text--secondary">
          {t.eySubtitle}
        </Typography>
        <Typography variant="h5">{t.eySubtitle2}</Typography>
      </div>
      <Spacer value={2} />
      <div className="container">
        <Spacer value={3} />
        <Grid container>
          <Grid item xs={12} sm={4}>
            <Typography variant="h4" gutterBottom>
              {t.ey1}
            </Typography>
            <Typography gutterBottom>
              {t.ey2 + ` `}
              <Typography component="span" className="text--primary">
                Markit Environmental Registry
              </Typography>
              .
            </Typography>
            <Typography gutterBottom>{t.ey3}</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div style={{ textAlign: "center" }}>
              <img src={treeAndSunset} alt="" height={250} />
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography gutterBottom variant="h4" gutterBottom>
              Plan Vivo
            </Typography>
            <Typography gutterBottom>{t.ey4}</Typography>
          </Grid>
        </Grid>
      </div>
      <Spacer value={5} />
      <div className="Overgang" style={{ color: "var(--textBlack)" }}>
        <div
          className="container"
          style={{ paddingTop: 20, paddingBottom: 20 }}
        >
          <Typography gutterBottom variant="h6">
            {t.ey5}
          </Typography>
          <Spacer />
          <Button
            variant="outlined"
            color="primary"
            onClick={() => (window.location.href = "https://www.planvivo.org/")}
          >
            {t.ey6}
          </Button>
        </div>
      </div>
      <Spacer value={5} />
      <div className="container">
        <Typography variant="h4" gutterBottom>
          {t.ey7}
        </Typography>
        <Typography variant="h6" gutterBottom>
          {t.ey8}
        </Typography>
        <TwoColumnGrid>
          <div>
            <Typography gutterBottom>{t.ey9}</Typography>
            <Typography gutterBottom>{t.ey10}</Typography>
            <Typography gutterBottom>{t.ey11}</Typography>
            <Typography gutterBottom>{t.ey12}</Typography>
          </div>
          <div className="text--center">
            <img src={pic6} alt="" height={400} className="big-img" />
          </div>
        </TwoColumnGrid>
        <Spacer />
        <Button
          component={Link}
          to="/network"
          variant="contained"
          color="primary"
        >
          {t.netTitle}
        </Button>
        <Spacer value={5} />
      </div>
    </div>
  );
}
