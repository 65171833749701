import { useContext } from "react";
import { Context } from "../context/Context";

const useTranslation = () => {
  const ctx = useContext(Context);

  return ctx.t[ctx.selectedLanguage];
};

export default useTranslation;
