import React from "react";
import header from "../assets/icons/Om os header 2-min.jpg";
import { Typography } from "@material-ui/core";
import Spacer from "../components/Layout/Spacer";
import useTranslation from "../hooks/useT";

export default function OmOs() {
  const t = useTranslation();

  const text1 = [t.oseat, t.oseat2, t.oseat3, t.oseat4, t.oseat5];

  const text2 = [t.os6, t.os7, t.os8, t.os9];

  return (
    <div>
      <div className="HomeImg">
        <Typography variant="h3" gutterBottom>
          {t.osTitle}
        </Typography>
        <img alt="" src={header} />
      </div>
      <Spacer value={3} />
      <div className="container">
        <Typography gutterBottom variant="h4">
          {t.oseatTitle}
        </Typography>
        {text1.map((t) => (
          <Typography gutterBottom>{t}</Typography>
        ))}
        <Typography gutterBottom variant="h4">
          {t.os1}
        </Typography>
        <Typography gutterBottom variant="h6">
          {t.os2}
        </Typography>
        <Typography gutterBottom>{t.os3}</Typography>
        <Typography gutterBottom>{t.os3_1}</Typography>
        <Typography variant="h4" gutterBottom>
          {t.os4}
        </Typography>
        <Typography variant="h6" gutterBottom>
          {t.os5}
        </Typography>
        {text2.map((t, index) => (
          <Typography gutterBottom>{t}</Typography>
        ))}
      </div>
    </div>
  );
}
