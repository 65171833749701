import React from "react";

export default function Overgang({ children }) {
  return (
    <>
      <div className="Overgang" style={{ overflow: "hidden" }}>
        <div
          className="container"
          style={{ padding: "50px 0", textAlign: "center" }}
        >
          {children}
        </div>
      </div>
    </>
  );
}
