import React from "react";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import {
  NaturePeople,
  ChatBubble,
  PlusOne,
  Eco,
  EmojiObjects,
  Public,
} from "@material-ui/icons";
import { iconStyle } from "../utils/data";

export default function BulletPoints({ t }) {
  const how = [
    {
      primary: t.netbp1,
      secondary: "",
      icon: <NaturePeople style={iconStyle} />,
    },
    {
      primary: t.netbp2,
      secondary: "",
      icon: <ChatBubble style={iconStyle} />,
    },
    {
      primary: t.netbp3,
      secondary: "",
      icon: <PlusOne style={iconStyle} />,
    },
    {
      primary: t.netbp4,
      secondary: "",
      icon: <Eco style={iconStyle} />,
    },
    {
      primary: t.netbp5,
      secondary: "",
      icon: <EmojiObjects style={iconStyle} />,
    },
    {
      primary: t.netbp6,
      icon: <Public style={iconStyle} />,
    },
  ];

  return (
    <List disablePadding>
      {how.map((howItem) => (
        <ListItem disableGutters>
          <ListItemIcon style={{ color: "var(--primary)" }}>
            {howItem.icon}
          </ListItemIcon>
          <ListItemText
            primary={howItem.primary}
            secondary={howItem.secondary}
          />
        </ListItem>
      ))}
    </List>
  );
}
