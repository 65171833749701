const URL = "https://jubirides.com"; //"http://167.172.102.88"; //"http://localhost:3000";

export const api = async (body, subject) => {
  const request = await fetch(`${URL}/eatco2`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...body, subject }),
  });
  const data = await request.json();
  console.log(data);
  return data;
};
