import React from "react";
import { Typography, Box } from "@material-ui/core";
import Overgang from "../components/Overgang";
import TwoColumnGrid from "../components/Layout/TwoColumnGrid";
import Spacer from "../components/Layout/Spacer";
import { SentimentVerySatisfied } from "@material-ui/icons";

export default function Customer() {
  return (
    <div className="gap">
      <div className="container">
        <Typography gutterBottom variant="h3">
          Bider du mærke i klimaet?
        </Typography>
        <Typography gutterBottom>
          I Nordeuropa har vi en af de største CO2 udledninger pr. indbygger i
          verden. Faktisk udleder vi hver især 17 ton CO2 om året, hvor af over
          25% kommer den mad vi spiser.
        </Typography>
        <Typography gutterBottom>
          Du er måske allerede begyndt at tænke mere over hvad du lægger i
          indkøbskurven, så hvorfor ikke også skænke miljøet en tanke når du er
          ude og spise?
        </Typography>
        <Typography gutterBottom>
          EatCO2 er et GreenProfit initiativ med en mission om at rette op på
          det enorme CO2 aftryk der bliver udledt i restaurationsbranchen.
        </Typography>
        <Typography gutterBottom>
          Ideen er IKKE at forandre på dine udespisevaner, vi mener at man skal
          kunne nyde at gå ud og spise i ny og næ, men samtidigt gøre noget godt
          for miljøet.
        </Typography>
        <Typography gutterBottom>
          For 10 kr. kan du fjerne du alle spor af CO2 fra din mad. Ren faktisk
          kompensere du for 5 gennemsnits måltider, så resten af bordet er også
          dækket ind.
        </Typography>
      </div>
      <div className="container">
        <Spacer value={1} />
        <Typography variant="caption">
          For at sikre at alle etiske og juridiske retningslinjer er fulgt, er
          alle CO2 kompenseringer registrerede i Markit-Registret og
          verificerede af tredjeparter, ifølge globalt annerkendte standarder.
          Vi driver ikke EatCO2 for at opnå et overskud, men skulle det ske, vil
          alle overskydende midler blive geninvesteret i andre klimaprojekter
          eller doneret til miljøorganisationer i Danmark. 9.25% går til
          administration.
        </Typography>
      </div>
      <Overgang>
        <TwoColumnGrid>
          <Typography variant="h3">Doner nu og beskyt planeten</Typography>
          <div>
            <Typography>Send 5580 til xx.xx.xx.xx</Typography>
            <img
              src="https://www.mobilepay.dk/mobilepaymedia/22c1646c92fb4fe7b35211524e91ba35.ashx"
              height={100}
              alt=""
            />
          </div>
        </TwoColumnGrid>
      </Overgang>
      <div className="container">
        <Spacer value={5} />
        <Box display="flex" justifyContent="center">
          <Typography variant="h5">
            Vi sigter efter at kompenserer for 5.000.000 ton CO2 - Tak for
            hjælpen
          </Typography>
          <SentimentVerySatisfied />
        </Box>
        <Spacer value={5} />
      </div>
    </div>
  );
}
