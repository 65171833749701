import { createMuiTheme } from "@material-ui/core";

export default createMuiTheme({
  palette: {
    primary: {
      main: "#34522a",
      light: "#F7F3BC",
      dark: "#34522a",
      contrastText: "white",
    },
    secondary: {
      main: "#C1BB00",
    },
  },
  typography: {
    fontFamily: "Poppins",
    h5: {
      fontWeight: 500,
      color: "var(--primary)",
    },
    h4: {
      fontWeight: "bold",
      color: "var(--primary)",
      lineHeight: "1.5",
    },
    h3: {
      fontWeight: "bold",
      color: "var(--primary)",
    },
    h2: {
      fontWeight: "bold",
    },
    h6: {
      color: "var(--primary)",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: "5em",
      },
      label: {
        fontWeight: "bold",
        textTransform: "none",
      },
    },
    MuiTypography: {
      gutterBottom: {
        marginBottom: "10px",
      },
    },
  },
});
