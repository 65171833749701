export default {
  da: {
    look: "Kommer snart med et nyt look....",
    dialogClose: "Luk",
    send: "Send",
    homeTitle: "Bid mærke i klimaet og gør en forskel",
    homeBtn: "Kom igang",
    homeH4: "I Danmark har vi et af verdens største klimaaftryk pr. indbygger.",
    homeH6: "Det skyldes til dels vores høje levestandard.",
    homeP:
      "Maden vi spiser, står alene for over 20 procent af vores fælles CO2 udledning, og især fødevarebranchen er tungt belastet.",
    homeP_bold:
      "Men vi danskere er også blandt de folkefærd i verden, der er allermest optaget af bæredygtighed. Faktisk vil to tredjedele af os gerne spise mere klimavenligt, hvis vi får muligheden.",
    homeP2:
      "Vi har derfor udviklet en metode der gør det muligt at nyde et kompromisløst måltid i ny og næ, og samtidigt have en positiv miljøpåvirkning.",
    homeP3: "Det koster kun lidt, det er nemt, og det virker.",
    homeH4_2: "Redskabet",
    homeOvergang:
      "Gør din virksomhed til en del af den bæredygtige dagsorden, på en engageret, ærlig og direkte måde.",
    homeRedskabetSubHeader:
      "EatCO2 er et redskab beregnet til restauranter og fødevarevirksomheder, der gerne vil gøre en forskel for klimaet.",

    homeP4:
      "Vi giver dig mulighed for at tage stilling til miljøet, side om side med dine kunder, på en nem og overskuelig måde.",
    homeP5:
      "Ved at få et lille ekstra beløb lagt på regningen, kan kunden nu opveje CO2-belastningen i deres måltid eller fødevareindkøb.",
    homeP6:
      "Beløbet bliver lagt i en CO2-pulje og brugt til at plante hurtigt voksende træer, der absorberer drivhusgasser fra atmosfæren.",
    homeP7:
      "………Så er der balance i klima-regnskabet, også selvom der ligger oksekød i kurven eller står bøf på menuen.",
    homeP8: "",
    kliTitle: "Klima mærker",
    kliSubTitle:
      "Vi har gjort det let for dig og sat en pris, så alle kan være med.",
    kliP:
      "Hvis du er nysgerrig og kunne tænke dig at blive en del af netværket, skal du først gøre op med dig selv, hvor mange klimamærker du regner med, kan omsættes i din restaurant.",
    kliP2:
      "Grundet tidens bæredygtige tendenser og relevans, er der mange der ønsker at have en selvstændig, positiv miljøpåvirkning. Men en god tommelfingerregel er at ca. 33% af dine gæster vil være positive indstillet. Dvs. at for hver 1.500 kuverter du omsætter, bør du bestille 500 mærker, men det kommer naturligvis an på størrelsen af din restaurant og dit flow af gæster.",
    kliP3:
      "….husk at dit initiale køb, bør ses på som en klima-investering, du henter tilbage i takt med at dine gæster kompenserer for deres måltid, og ikke som en udgift. Når du løber tør for klima-mærker, bruger du blot din CO2-omsætning og bestiller nogle nye.",
    kliP4:
      "Som en del af netværket modtager du et certifikat på, at du er blevet klimaambassadør, og at din virksomhed aktivt arbejder for at nedbringe CO2.",
    kliP5:
      "Hvert kvartal opgør vi, hvor meget CO2 du har været med til at kompensere, og en gang om året kårer vi, ved navns nævnelse, den virksomhed i netværket, der har haft den største positive klimapåvirkning.",
    kliBtn: "Vil du have en snak med os først?",
    kliInput: "Virksomhed",
    kliInput2: "Tlf. nr",
    kliInput3: "Adresse",
    kliInput4: "Post nr.",
    kliInput5: "Bynavn",
    kliCap: "Vælg antal klimamærker",
    kliPrice: "Pris",
    kliTax: "Moms",
    kliReadRules: "Læs betingelser",
    kliAcceptRules: "Godkend købsbetingelser",
    kliBtn2: "Bestil nu",
    kliCertifikat: "Certifikat",
    footerImprove: "Vi værtsætter din mening, tak for hjælpen",
    footerRestaurant: "Restaurant navn",
    footer1: "Din mening",
    footer2: "Anbefal en restaurant",
    footer3: "Tilbagekald sammentykke",
    footer4: "Forbedredningsforeslag",
    footer5: "Dokumenter",
    footerInfo1: "Handelsbetingelser",
    footerInfo2: "Privatlivspolitik",
    strTitle: "Om os",
    hvemViEr: "Hvem vi er",
    strategyTitle: "Vores strategi",
    coTitle: "Klima beregning",
    netTitle: "Vil du vide mere?",
    networkTitle: "Bliv en del af netværket",
    faqTitle: "Mere info",
    fooTitle: "Fødevarer",
    nav1: "Klima kompensering",
    snackNetwork: "Tak for din henvendelse, vi kontakter dig snarest muligt",
    snackImprove: "Tak for dit forslag",
    snackSamtykke:
      "Vi har registret din samtykketilbagekaldelse. Inden for de næste 24 timer vil dine informationer blive slettet fra vores system.",
    snackRestaurant: "Tak for din anbefaling",
    snackNews: "Tak for din tilmelding",
    dish1: "Hummer Thermidor",
    dish2: "Bøf m. sauce bearnaise og bagt kartoffel",
    dish3: "Lammekoteletter m. persillepesto og Sommer salat",
    readMore: "Læs mere",
    dagsOrden: "Bæredygtig dagsorden",
    do1:
      "Ikke kun fra sidelinjen med pæne hensigtserklæringer, passive donationer eller køb af klimakreditter.",
    do2:
      "Som netværks ambassadør, bruger du din virksomhed som katalysator i kampen for klimaet. Det betyder, at du giver gæster eller kunder, der ønsker at tage ansvar, mulighed for at modvirke miljøpåvirkningen, af deres måltid eller fødevarer indkøb.",
    do3:
      "For restauranter regner vi os, på baggrund af forskellige faktorer, frem til en gennemsnitlig CO2 belastning pr. kuvert, (CO2DnX). Hvilket er den værdi, der skal kompenseres for at modvirke klimaaftrykket i et sammensat måltid.",
    do4:
      "Regnestykket på enkelte fødevaregrupper, kan være en smule mindre gennemskuelige. Repræsenterer du derfor en food-servicevirksomhed, et supermarked eller et catering bureau, bør du kontakte os direkte på: ",
    sådanGørDu: "Bliv ambassadør",
    initiativTitle: "Aktiver dine kunder",
    initiativP:
      "Ved at give dine kunder/gæster mulighed for at tage selvstændig stilling til miljøet, styrker du ikke alene relationerne til dem, men du øger også sandsynligheden for at de bakker op om initiativet og bliver klimafortalere for din virksomhed.",
    initiativP2:
      "Det skyldes bla. at en kunde der selv tager stilling, føler sig involveret, og er derfor langt mere tilbøjelig til at huske budskabet, og dele det med andre, længe efter vedkommende har forladt din forretning.",
    initiativP3: "",
    step1_cat: "Hotel & Restaurant",
    step1: "The ”Feel good factor”",
    step1_1_1:
      "Kombiner det luxuriøse ved at spise ude, med følesen af at give noget tilbage til planeten.",
    step1_1_2:
      "En god tommelfingerregel er at mindst 33% af dine gæster vil være positive indstillet.",
    step1_2:
      "Dvs. at for hver 300 kuverter du omsætter, vælger omtrent 100 sige ja til at klimakompenserer deres måltid. Men det kommer naturligvis an på hvor proaktive I er til at forklare om initiativet.",
    step2_cat: "Catering",
    step2: "Bæredygtighed til døren",
    step2_1:
      "Frokostordninger til virksomheder, måltidskasser, eller råvarer produkter til hoteller og restauranter mm.",
    step2_1_2:
      "Giv dine kunder mulighed for at modveje klimaaftrykket af deres køb.",
    step2_2:
      "Klimakompenseringen sker ved at vi sammenregner CO2 aftrykket af kundens indkøb, (evt. i jeres webshop), hvorefter vedkommende kan vælge at modsvarer denne, ved at betale en lille merpris.",
    step3_cat: "Fødevarer",
    step3: "Klima i dagligdagen",
    step3_1:
      "Repræsenterer du eks. et supermarked, og overvejer at give dine kunder mulighed for at CO2-kompensere for deres indkøb, (evt. via jeres egen APP), eller direkte ved kassen når regningen skal betales?",
    step3_2:
      "Vi gør det let og overskueligt at komme i gang…..uden at du behøver at investere store summer og bruge enorme mængder af tid.",
    stepNote:
      "Klimakompenseringen sker ved at vi sammenregner CO2 aftrykket af kundens indkøb, (evt. i jeres webshop), hvorefter vedkommende kan vælge at modsvarer denne, ved at betale en lille merpris.",
    footerDialog1: "Forbedringsforeslag",
    footerDialog2: "Tilbagekald samtykke",
    footerDialog3: "Anbefal restaurant",
    textFieldKommentar: "Kommentar",
    foodDesuden: "Desuden",
    foodTilberedning: "Tilberedning",
    foodTotal: "Total",
    foodMængde: "Mængde",
    foodProduct: "Produkt",
    foodIndhold: "CO2-indhold",
    foodIndholdPr: "CO2-indhold pr. enhed",
    foodDisclaimer:
      "  * Alle tal er udregnet ud fra data leveret af henholdsvis fødevarestyrelsen og landbrugsstyrelsen. Der vil fra tid til anden være variabler i kalkylerne der kan forandre facit, men vi har forsøgt at være så konservative som muligt i vores beregninger.",
    food11: "Kogt hummer (250 g)",
    food12: "Sauce",
    food13: "Skalotteløg",
    food14: "Smør",
    food15: "Hvidvin",
    food16: "Hvedemel",
    food17: "Fiskebouillon",
    food18: "Hvid peber",
    food19: "Dijonsennep",
    food110: "Piskefløde",
    food111: "Revet emmertalerost",
    food113: "Flute",
    food114: "Pynt",
    food115: "Hovedsalat",
    food116: "Dild, frisk",
    food117: "Citron",
    dish1Short: "Hummer",
    dish2Short: "Oksekød",
    dish3Short: "Lammekød",
    food21: "Bøf m. sauce bernaise og bagt kartoffel",
    food22: "Oksekød",
    food23: "Bearnaise sauce",
    food24: "Æg",
    food25: "Salt og peber",
    food26: "Citronsaft",
    food27: "Estragon",
    food28: "Bernaise essens",
    food29: "Creme fraiche",
    food210: "Bagt kartoffel",
    food211: "Sæson grøntsager",
    food212: "Smør til stegning mm.",
    food31: "3 lammekoteletter",
    food32: "Pesto",
    food33: "Kruspersille",
    food34: "Hvidløg",
    food35: "Olivenolie",
    food36: "Groft salt",
    food37: "Salat",
    food38: "Babyspinat",
    food39: "Hjertesalat",
    food40: "Sesamolie",
    food41: "Grape",
    food42: "Cashewnødder",
    food43: "Håndfuld hakket rosmarin",
    food44: "1 fed hakket hvidløg",
    food45: "Hakket persille",
    food46: "Revet citronskal",
    food47: "Urtesmør",
    beregning1: "Estimater fra det virkelige liv",
    beregning2:
      "Nogle måltider indeholder selvfølgelig mere CO2 end andre, men hovedreglen er, at man kompenserer for CO2-belastningen af et sammensat måltid for 1 – 2 kr. uanset om der er tale om forret, hovedret eller dessert. Det kan dog variere fra restaurant til restaurant.",
    beregning3:
      "Er der tale om firmafrokost kuverter, måltidskasser mm., vil belastningen ofte være mindre end gennemsnittet, da maden bliver tilberedt i store mængder, hvilket sænker klimaaftrykket, frem for enkelte a la carte måltider.",
    beregning4:
      "Det samme gør sig gældende for eks. en ”Pizzaria pizza” eller en burger, hvor klimabelastningen kan afvejes for ned til mellem 0.20 og 0.40 øre.",
    beregning5: "3 retter",
    beregning6:
      "Bemærk: Vores estimater er med vilje beregnet konservativt beregnet",
    net1: "",
    net2: "",
    net3: "",
    net4:
      "Grundet tidens bæredygtige tendenser og relevans, er der mange der ønsker at have en selvstændig, positiv miljøpåvirkning.",
    net5:
      "Gør din virksomhed til katalysator i kampen for klimaet, på en ærlig, direkte og opsigtsvækkende måde.",
    eatco2idag: "EatCO2 i dag",
    bestil: "Bestil med det samme",
    bestilBtn: "Gå til bestilling",
    netbp1: "Styrk din bæredygtige profil",
    netbp2: "Knyt stærkere bånd med dine gæster/kunder",
    netbp3: "Få øget positiv omtale",
    netbp4: "Tiltræk nye miljøfokuserede gæster/kunder",
    netbp5: "Gør en forskel på en ny spændende måde",
    netbp6: "Støt FNs 17 verdensmål",
    formVirksomhed: "Virksomhed",
    formKontakt: "Kontaktperson",
    email: "Email",
    tlf: "Telefon",
    kontaktMig: "Kontakt mig",
    formDisclaimer:
      " * Jeg accepterer, at EatCO2 ApS (SVR-nr. 38659928), må kontakte mig på e-mail eller telefon vedrørende mulighederne for at blive en del af leverandørnetværket.",
    option1: "Hotel & Restaurant",
    option2: "Catering",
    option3: "Fødevarer",
    osTitle: "Hvem vi er og hvad vi står for",
    oseatTitle: "Hvem vi er",
    oseat:
      "I Nordeuropa gør vi meget ud af vores mad. Den er samlingspunktet for familien i den travle hverdag, og muligheden for lidt ekstra ”Hygge”, ved specielle anledninger.",
    oseat2:
      "Det betyder ikke nødvendigvis at vi skal spise oksekød og lam hverdag. Men vi mener, at for at bevare de sociale traditioner der ligger bag vores nordeuropæiske spisevaner, skal der være gjort plads til at nyde et kompromisløst måltid i ny og næ.",
    oseat3:
      "Vi har derfor udviklet en metode der skaber balance på klima-vægtskålen.",
    oseat4:
      "Vi arbejder ud fra en ”mange bække små strategi” der, for et beskedent beløb, giver forbrugere mulighed for at modvirke CO2-udledningen fra sammensatte måltid og fødevareprodukter.",
    oseat5:
      "Beløbet bliver lagt i en CO2-pulje og brugt til at plante hurtigt voksende træer, der optager CO2 fra atmosfæren. Den absorberede CO2 bliver nøje målt og verificeret, og derefter delt op i mindre portioner, der kan tilpasses et hvilket som helst måltid eller fødevareprodukt.",
    os1: "Vores mission",
    os2:
      "Vi brænder for bæredygtighed og tror på, at nøglen til at løse vores klimaproblemer skal komme fra den enkelte.",
    os3:
      "Vores mission er at engagere forbrugere, i at gøre en selvstændig forskel for klimaet, og samtidigt bidrage til positive sociale påvirkninger, der støtter op om FNs 17 verdensmål. ",
    os3_1:
      "Vi har med andre ord, skabt en ramme, der gør det let for forbrugerne i at træffe klimarigtige valg til gavn for et mere bæredygtigt samfund.",
    os4: "Manifest",
    os5:
      "Klimaforandringer og miljøudfordringer er vores absolut største prioritet.",
    os6:
      "Vi har sat grundlæggende mål for, hvordan vi kan bidrage ved at integrere bæredygtighed i vores kerneaktiviteter og gøre det afgørende for vores langsigtede mission.",
    os7:
      "Vi tror på, at vi kommer længst ved at løfte sammen, og at små ændringer fra os alle kan få enorm betydning. Derfor arbejder vi for et bedre klima ved at inddrage forbrugerne og gøre det let for dem at tage stilling.",
    os8:
      "Vi stræber efter at være frontløber i udviklingen af et mikro-kompenserings-system, der kan tilpasses alle fødevaregrupper, og som hvis det organiseres korrekt, kan bidrage enormt til at reducere CO2-emissioner.",
    os9:
      "Vi forsøger at påvirke alle med relation til fødevarer produktion, salg eller tilberedning, til at deltage i initiativet og bruge deres virksomhed som katalysator i kampen for klimaet.",
    eyTitle: "Vores strategi",
    eySubtitle: "Vores kerneværdier er troværdighed og gennemsigtighed.",
    eySubtitle2: "De er fundamentet under alt, hvad vi gør.",
    ey1: "Vores metode",
    ey2:
      "Vi arbejder kun med verificerede procedurer og fører protokol over alle CO2-kompensationer, som hver især får et serienummer, der registreres i det internationale",
    ey3:
      "Vores projekter finder sted i Mellem- og Sydamerika under Plan Vivo-paraplyen, hvilket er en global anderkendt standard, der sikrer, at alle etiske og juridiske retningslinjer er fulgt i forbindelse med plantningen, samt den relaterede CO2-kompensation.",
    ey4:
      "Plan Vivo er etableret i 1996 med base i Edinburgh og er den ældste standard for certificering af klimabeskyttelsesprojekter inden for jordbrugssektoren. Plan Vivo har desuden i 2019 vundet FN’s prestigefyldte Equator Prize, der gives til særligt gavnlige miljø og klimaprojekter.",
    ey5:
      "Genplantning og skovudnyttelsesprojekter modtager først Plan Vivos godkendelse, når de opfylder en række høje krav. Projekterne skal baseres og organiseres lokalt, så de gavner lokale landbrugsfamilier. F.eks. ved at de skaber job, forbedrer infrastrukturen, medfører bedre sundhed og styrker økonomiske forehold. Det er disse kvaliteter, der er med til at gøre Plan Vivo til en af de mest troværdige og stærkeste standarder i verden",
    ey6: "Læs mere om Plan Vivo",
    ey7: "Vi gør en forskel sammen",
    ey8:
      "Vi planter med en strategi om at skabe langsigtede indtægtsmuligheder for landmænd i nogle af de fattigste dele af verden.",
    ey9:
      "Det giver mening, for det også er dem, der er mest sårbare over for konsekvenserne af klimaforandringer.",
    ey10:
      "Vores projekter tilskynder små landbrugsfamilier til at plante skov på de underudnyttede dele af deres jord. Vi betaler dem så løbende for deres indsats, efterhånden som træerne begynder at optage CO2.",
    ey11:
      "På den måde har landmændene en investeret interesse i at plante og vedligeholde deres plantager, så de opnår de mest optimale vækstforhold. Derudover bliver der indført andre landbrugsaktiviteter mellem træerne på plantagerne, hvorfra indtægterne er designet til at være forskudte i forhold til den traditionelle landbrugssæson. På den måde spredes indkomsten ud over året.",
    ey12:
      "De landmænd, der deltager i vores projekter, modtager betalinger for deres indsats over en periode på 10 år. Det tilfører en solid vækst og økonomisk sikkerhed over lange perioder.",

    faqTitle1: "Ideen bag initiativet",
    faqIde1:
      "Ideen bag EatCO2 er, at vi skal holde fast i vores glæde og vaner med at spise ude. Men vi mener, at der skal være plads til at nyde et kompromisløst måltid i ny og næ især når man samtidig gør noget godt for miljøet.",
    faqIde2:
      "Vi har udviklet EatCO2 med det formål at kombinere det luksuriøse ved at spise ude med følelsen af at give noget tilbage til planeten på en nem og overskuelig måde.",
    faqIde3:
      "Vi arbejder ud fra en ”mange bække små strategi”, der giver forbrugere mulighed for at kompensere CO2-udledningen fra deres måltid, for at beskedent beløb, der for de fleste er ubetydeligt, når regningen skal betales.",
    faqIde4:
      "Beløbet bliver lagt i en CO2-pulje og brugt til at plante hurtigt voksende træer, der optager CO2 fra atmosfæren. Den absorberede CO2 bliver nøje målt og verificeret, og derefter delt op i mindre portioner, der kan tilpasses et hvilket som helst måltid eller fødevareprodukt.",
    faqTitle2:
      "Greenprofit – mere end 90 procent går direkte til et bedre klima",
    faqGreen:
      "EatCo2 er et green profit initiativ. Det betyder, at vi driver virksomheden med meget få udgifter til administration. Eventuelle overskydende midler vil blive brugt til at plante flere træer, reinvesteret i nye klimaprojekter til gavn for miljøet eller doneret til velgørende organisationer i Danmark. Højest 9,25 procent af vores omsætning går til dækning af administration og interne omkostninger.",
    faqTitle3: "Ingen gamle klima-kreditter",
    faqKredit:
      "Vores filosofi er at vi skal gavne miljøet med nye friske tiltag frem for at købe os til andres grønne samvittighed. Det betyder også, at alle vores CO2-kompensationer sker ved at plante nye træer og ikke ved at købe fra en gammel skov.",
    faqTitle4: "Vi planter ikke i Danmark",
    faqPlanter1:
      "Vi har bevidst valgt ikke at plante træer i Danmark, hvilket der er flere årsager til. Ved at plante uden for Danmark, i tropiske klima, kan vi have en langt større påvirkning. Et varmere klima gør, at træer vokser hurtigere og derfor opsuger mere CO2 på kortere tid end f.eks. et grantræ i Danmark. Men at vi samtidigt beskytter biodiversiteten i nogle at de mest unikke og sårbare økosystemer vi kender til, er et altafgørende parameter for os.",
    faqPlanter2:
      "Derudover, styrker vi økonomisk vækst i nogle af de fattigste samfund i verden og støtter op om 5 ud af FNs 17 verdensmål for en bæredygtig fremtid.",
    faqTitle5: "Beregnings formularen",
    faqFormula1:
      "Vores beregninger er målt ud fra en gennemsnitlig CO2-udledning for et måltid, (CO2DnX). Men vi sætter barren højt, så de fleste måltider udleder ikke nær så meget CO2, som vi kompenserer for.",
    faqFormula2:
      "Eksempelvis indeholder et gennemsnitligt måltid ca. 6 - 8 kg. CO2, og en standard kompensation dækker 10 kg. Med andre ord overkompensere vi, som regel, med ca. 25 - 66 procent, men det kan naturligvis variere fra restauration til restauration.",
    faqFormula3:
      "I enkelte fødevaregrupper, varierer CO2 belastningen enormt, hvilke gør der svære at sætte en CO2 fællesnævner. Vi overkompenserer derfor som standard, på supermarked niveau, med 5-10 procent over hele linjen, uanset om der er tale om agurker, bananer eller makrel i tomat.",
    faqFormula4: "",
    readMore2: "Du kan læse mere her",
    faqTitle6: "Klimakompensering",
    faqMærke1:
      "En klimakompensering, (CO2DnX), er CO2 vægten af måltider og fødevare produkter, dvs. hvor meget CO2 der skal kompenseres, for at modsvare klimaaftrykket i råvaren eller måltidet.",
    faqMærke2:
      "Klima-kompenseringer har forskellig vægt og værdi i forhold til hvad der skal kompenseres. Eks. vil et sammensat måltid vil ofte have en større miljøpåvirkning end et enkelt råvareprodukt, ligesom kød har en større CO2-udledning end grøntsager.",
    faqTitle7: "Vores mad er en nøgle til at løse klimaforandringer",
    faqNøgle:
      "Hvis vi skal stoppe de menneskeskabte klimaændringer, skal en gennemsnitsdansker over de næste 20 år reducere sin miljøpåvirkning med mindst 80 procent. ",
    faqNøgle2:
      "Da vi ved, at omkring en femtedel af vores CO2-udledninger kommer fra vores kost, mener vi, at det er der, hvor vi meget effektivt kan gøre en mærkbar forskel.",
    faqNøgle3:
      "Det kræver naturligvis vi gør tingene på helt nye måder, men specielt virksomheder, der beskæftiger sig med fødevareproduktion og tilberedning, kan bane vejen for en forandring på forbrugerniveau.",
    faqTitle8: "CO2 i kosten",
    faqKost1:
      "I Danmark udledes årligt 15 mio. ton CO2 fra vores mad. Det svarer til ca. 20% af de samlede udledninger og er omtrent dobbelt så meget, som alle danske personbiler udleder årligt.",
    faqKost2:
      "Vi spiser meget kød i Danmark og at vegetarisk mad er bedre for klimaet end animalske fødevarer er der vist ingen tvivl om. Men til gengæld er der for det meste også gram for gram mindre næring i den plantebaserede kost. Derfor vil man skulle indtage flere plantebaserede fødevarer for at komme frem til det samme næringsindhold.",
    faqKost3:
      "Man kan altså ikke bare lave en ”tomat/frikadelle” sammenligning, når man måler CO2-aftrykket mellem vegetariske og animalske fødevarer. I stedet skal man kigge på det samlede aftryk fra flere plantebaserede fødevarer, så næringsindholdet er lig med den animalske fødevare.",
    faqKost4:
      "Vi mener at det skal være plads til os alle uanset om man er veganer, vegetarianer eller kødspiser, så længe vi skænker klimaet en tanke. Vælg sæsonrigtigt, minimer madspild og hold øje med CO2.",
    faqTitle9: "CO2 i alkohol",
    faqa1:
      "Alkohol som gruppe kommer ind på en fjerdeplads blandt fødevarernes CO2-aftryk i Danmark og bør ikke overses. Den miljømæssige påvirkning fra de forskellige typer alkohol er baseret på de ingredienser, alkoholen er fremstillet af. Druer til vin, sukkerrør til rom, byg til øl, æbler til cider osv.",
    faqa2:
      "Miljøpåvirkningen bliver bla. målt ud fra hvor meget gødning og vand, der er brug for, og om dyrkningen hæmmer biodiversitet. Det kan f.eks. være tilfældet, hvis skove bliver fældet for at give plads til ny landbrugsjord.",
    faqa3:
      "Mange af de store spiritusproducenter har introduceret en række tiltag i forhold til at bruge ren energi og bedre nedkølingsfaciliteter samt nedsætte vandforbruget. Det er tiltag, der mindsker CO2-udledningen i produktionen, men de fjerner den langt fra.",
    faqTitle10: "Paris-aftalen",
    faqP: "Danmark har et af verdens største klimaaftryk målt pr. indbygger.",
    faqP2:
      "Hver især udleder vi 17 ton CO2 om året, hvor af en femtedel kommer fra den mad vi spiser. Men vi har også en enorm gæld til klimaet som resultat af den industrielle velfærdsudvikling. Derfor mener vi, at det er vores kollektive ansvar at være frontløber i den bæredygtige udvikling ved at støtte op om nye miljøskånsomme initiativer.",
    faqP3:
      "I 2015 indgik FN-landene en klimaaftale, der forpligter os til at holde temperaturstigningerne under 2 grader og helst på 1,5 grader i forhold til niveauet før industrialiseringen. Allerede nu er temperaturen steget med ca. 1 grad, så det haster.",
    faqP4:
      "Hvert andet år skal medlemslandene rapportere til FN om, hvordan det går med at nå klimamålene og vores arbejde for at reducere CO2-udslippet.",
    faqP5:
      "Endelig skal rige lande hjælpe fattige med at finansiere omstillingen til grøn energi. Fra 2020 skal der årligt genereres 100 mia. dollar til at støtte formålet.",
    faqTitle11: "Vi taler ind i FN's verdensmål",
    faqFn1:
      "FN’s 17 verdensmål sigter mod en bæredygtig fremtid og blev vedtaget af medlemslandene i 2015. Verdensmålene spænder vidt fra borgerrettigheder til et rent havmiljø.",
    faqFn2:
      "Ved at indgå i EatCO2-netværket arbejder du også automatisk for Verdensmål 1 der drejer sig om at afskaffe fattigdom, Verdensmål 11 om at støtte et bæredygtigt samfund og innovation, Verdensmål 13 der arbejder for at bekæmpe klimaforandringer, Verdensmål 15 om at beskytte liv på land, og Verdensmål 17, hvor målet er at fremme globale klima-partnerskaber.",
    readMoreVerden: "Læs mere om verdensmålene",
    fvTitle: "Fødevarer",
    fvSub1: "Dagligvarer – danskerne tænker bæredygtigt",
    fv1: "Fødevarerne i supermarkedet har en stor miljøpåvirkning.",
    fv2:
      "I takt med at klimadagsordenen helt generelt er blevet vigtigere for mange danskere, spiller bæredygtighed i forhold til egne indkøb også en stigende rolle. En undersøgelse fra Landbrug og Fødevarer viste i 2019, at to tredjedele af danskerne tænker over bæredygtighed, når de køber mad og drikke.",
    fv3:
      "Det betyder også, at mange forsøger at orientere sig i forhold til, hvad klimabelastningen ved de enkelte varer er, men regnestykkerne er indviklede og ofte også overraskende.",
    fvSub2: "Grøntsager – et indviklet regnestykke",
    fv5:
      "Tag f.eks. grøntsager. På nogle tider af året kan grøntsager have et større CO2-aftryk, når de produceres i Danmark end i Sydeuropa. Det skyldes, at vi i Norden ofte dyrker grøntsager i drivhus for at have lækre råvarer hele året rundt. Men et drivhus har et enormt energiforbrug til lys og varme. Det resulterer i et langt større klimaaftryk end, hvis grøntsagerne dyrkes under åben himmel i udlandet og derefter transporteres til Danmark i lastbil.",
    fv6:
      "Forklaringen er, at transporten kun udgør lille del af varens samlede påvirkning på miljøet. Selve produktionen er langt vigtigere. Faktisk udgør produktionen helt op til 80 procent af den samlede miljøpåvirkning sammenlignet med transport på kun 15 procent og indpakning de sidste 5 procent.",
    fvOvergang: "To ud af tre danskere ønsker at spise mere bæredygtigt",
    fvSub3: "Vores løsning",
    fv7:
      "I takt med at befolkningen vokser, stiger behovet for fødevarer, hvilket lægger et øget pres på klimaet. Derfor har vi ”gearet” vores CO2 værktøj, så også fødevareproducenter har mulighed for at deltage i kampen for klimaet.",
    fv8:
      "I praksis betyder det at vi giver dig mulighed for at neutralisere klimaaftrykket i dine fødevareprodukter helt ned i enkelte gram CO2.",
    call: "Modtag vores nyhedsbrev og støt bæredygtigheden",
    call2:
      "Formålet med vores nyhedsmail er at dele information om bæredygtige trends, iværksætteri og produkter, med et håb om at kunne påvirke flere danskere til at indfører miljøskånsomme tiltag i hverdagen.",
    call3:
      'Du modtager max. en mail i kvartalet og kan til en vær tid tilbagekalde dit samtykke i bunden af hjemmesiden, under ”tilbagekald samtykke"',
    call4: "Tak for hjælpen",
    name: "Navn",
    hand: "Terms of purchase",
    hand1:
      "EatCo2 (Reg. DK30959834), er et Dansk ”GreenProfit” selskab der beskæftiger sig med at plante træer over hele verden, med henblik på at kompenserer for Co2 udledning i fødevarer branchen.",
    hand2:
      "EatCo2 giver restauranter og fødevare producenter mulighed for at modvirke deres miljøpåvirkning gennem donationer eller betalinger til Micro-kompensering af dagligvarer eller hele måltid, ved gennemførelse af skovplantning der resulterer i absorbering af drivhusgasser fra atmosfæren.",
    hand3: "Betaling",
    hand4:
      "Køb af vare eller ydelser igennem EatCo2 er underlagt moms, (25%), medmindre kunden er fritaget for moms",
    hand5:
      "Medmindre andet er aftalt, forfalder fakturaer 7 kalenderdage efter fakturering. Betaling sker til den konto, der er angivet på fakturaen. Alle bankgebyrer, der er forbundet med betalingen, skal bæres af køberen og må ikke sænke det samlede fakturerede beløb.",
    hand6: "Forsinkede betalinger er underlagt danske rentelovsbestemmelser.",
    hand7: "Brug af etiketter, navne og logoer",
    hand8:
      "Når der indgås et samarbejde med EatCO2 gives der en ikke-eksklusiv, ikke overførbar ret til at kunden må bruge EatCO2s navn og logo til offentliggørelsesformål.",
    hand9:
      "Kunden må ikke foretage nogen ændring eller kopieringer af EatCO2-mærket eller materiale i alt almindelighed, med mindre at andet er aftalt. Kunden er opmærksom på at EatCO2 vil håndhæve sine rettigheder mht. copyright og varemærkebeskyttelse til det fuldeste omfang af loven.",
    hand10:
      "EatCO2 kan bruge data til statistiske eller videnskabelige formål. EatCo2 kan desuden navngive kunden ved henvisning til en EatCO2 hjemmeside eller i andre medier, medmindre parterne har aftalt andet.",
    hand11:
      "Retten til at bruge EatCO2-mærket ophører i tilfælde af skade til EatCO2s omdømme, eller hvis det vurderes at kundens handlinger er i modstrid med værdierne og målene for EatCO2.",
    hand12:
      "Ved afslutning af samarbejdet må materialer og rettigheder (især logoer og mærker), ikke længere anvendes og skal om nødvendigt returneres. Dette inkluderer ikke at citerer samarbejdet til referenceformål.",
    hand13: "Kundedata",
    hand14:
      "EatCO2 behandler data modtaget fra kunden, fortroligt i overensstemmelse med GDPR-lovgivningen. ",
    hand15:
      "EatCO2 kan dog bruge data til statistiske, videnskabelige eller reklamemæssige formål. Hvis sådanne data offentliggøres, anonymiserer vi kundenavnet og offentligøre det først ved udtrykkelige givet samtykke fra kunden.",
    hand16:
      "EatCO2 ejer ophavsretten til alle statistiske rapporter genereret af EatCO2, på baggrund af data vi indsamler.",
    hand17: "Retningslinjer",
    hand18:
      "EatCo2 beregner vores klima påvirkning ud fra de oplysninger der er tilgængeliggjorte af vores samarbejdspartnere. Selvom beregningsgrundlagene anses som troværdige, er EatCo2 ikke ansvarlig for unøjagtige, fejlagtige eller ufuldstændige data eller for beregningsfejl.",
    hand19:
      "EatCo2 er i øvrigt ikke ansvarlig for skader på grund af faktorer uden for EatCo2s kontrol, herunder men uden begrænsning til naturkatastrofer, politisk uro, krig, terrorisme, mangel på råmaterialer, begrænsninger i brugen af energi eller svigt i den tekniske infrastruktur.",
    hand20: "Fradrag & Markedsføring",
    hand21:
      "Når der indgår et element af mod-ydelse, (fx markedsføring), i forbindelse med køb af ”micro-kompenseringer”, har kunden som hovedregel fradrag for hele beløbet som driftsudgift.",
    hand22:
      "Det er op til kunden selv at bedømme, evt. efter samråd med revisor, om beløbet kan fratrækkes som en driftsomkostning, (fx reklameudgift). Jfr. statsskattelovens § 6. https://skat.dk/SKAT.aspx?oid=2083469",
    hand23: "Aftaleændringer",
    hand24:
      "EatCo2 ønsker muligvis at forandre disse handelsbetingelser. Den aktuelle version offentliggøres på hjemmesiden og / eller sendes til kunden.",
    hand25:
      "Efterfølgende ændringer af handelsbetingelserne vil blive en integreret del af aftalen, hvis kunden ikke skriftligt gør indsigelse mod dem inden 30 dage efter, at handelsbetingelserne er blevet offentliggjort online og / eller sendt til kunden.",
    hand26:
      "Medmindre andet er aftalt, er enhver kundeforhold reguleret af dansk lov.",
    varemaerke: "Varemærke",
    tsk: "tsk",
    spsk: "spsk",
    fed: "fed",
  },
  en: {
    look: "Coming soon with a new look....",
    dialogClose: "Close",
    send: "Send",
    homeTitle: "Food for thought, make a difference",
    homeBtn: "Get started",
    homeH4:
      "In Northern Europe we have one of the world's largest carbon footprints per individual",
    homeH6: "This is partly due to our high standard of living.",
    homeP:
      "Just the food we consume accounts for about 20-25 percent of our joint CO2 emissions, and the food industry in particular is a heavy burden.",
    homeP_bold:
      "However, we Northern Europeans are also among the people in the world who are most focused on sustainability. In fact, studies show that two thirds of us would prefer to eat more sustainable if given the opportunity.",
    homeP2:
      "We have therefore developed a method that makes it possible enjoy an uncompromised meal once in a while, and at the same time have a positive impact on the environment.",
    homeP3: "It's cheap, easy, and it works.",
    homeH4_2: "The tool",
    homeOvergang:
      "Make your business a part of the sustainable agenda, in a committed, honest and direct way.",

    homeRedskabetSubHeader:
      "EatCO2 is a tool designed for restaurants, food producers and distributers, that want to make a difference.",

    homeP4:
      "We enable you to take a stand for the environment, side by side with your customers, in an easy and transparent way.",
    homeP5:
      "By having a small extra amount added to the bill, your customers can now offset the carbon footprint of their restaurant meal or groceries.",
    homeP6:
      "The extra amount is added to a CO2 deposit and used to plant fast-growing trees that absorb greenhouse gases from the atmosphere.",
    homeP7:
      "……… In this way the environmental balance is restored, even if there is beef in the shopping cart or steak is on the menu.",
    homeP8: "",
    kliTitle: "Climate stamps",
    kliSubTitle:
      "We've made it easy for you and set a price so everyone can partake.",
    kliP:
      "If you are curious and would like to become part of the network, you first have to decide how many climate stamps you expect can be offset at your establishment.",
    kliP2:
      "Due to the global sustainability trends and relevance of the time, many people wish to have an independent, positive environmental impact.",
    kliP3:
      "...Remember that your initial purchase should be viewed as a climate investment you recoup as your guests compensate for their meal, and not as an expense. When you run out of climate stamps, you just use your CO2 revenue and order some more.",
    kliP4:
      "As part of the network, you will receive a certificate that shows that you have become a climate ambassador, and that your company actively works to reduce CO2 emissions.",
    kliP5:
      "Every quarter, we add up how much CO2 you have helped to compensate and once a year, by name, we announce the company in the network that has offset the most CO2 emissions.",
    kliBtn: "Do you want to talk with us first?",
    kliInput: "Company",
    kliInput2: "Phone number",
    kliInput3: "Address",
    kliInput4: "Postal",
    kliInput5: "City",
    kliCap: "Choose amount of climate stamps",
    kliPrice: "Price",
    kliTax: "VAT",
    kliReadRules: "Read terms of conditions",
    kliAcceptRules: "Accept terms",
    kliBtn2: "Order now",
    kliCertifikat: "Certificate",
    footerImprove: "We appreciate your opinion, thank you",
    footerRestaurant: "Restaurant name",
    footer1: "Your opinion",
    footer2: "Recommend a restaurant",
    footer3: "Unsubscribe newsletter",
    footer4: "Improvement suggestions",
    footer5: "Documents",
    footerInfo1: "Terms and conditions",
    footerInfo2: "Privacy policy",
    strTitle: "About us",
    hvemViEr: "Who we are",
    strategyTitle: "Our strategy",
    coTitle: "CO2 calculation",
    netTitle: "Do you want to know more?",
    networkTitle: "Become a part of the network",
    faqTitle: "More info",
    fooTitle: "Groceries",
    nav1: "Climate Compensation",
    snackNetwork:
      "Thanks for your inquiry, we will contact you as soon as possible",
    snackImprove: "Thank you for your suggestion",
    snackSamtykke:
      "Vi har registret din samtykketilbagekaldelse. Inden for de næste 24 timer vil dine informationer blive slettet fra vores system.",
    snackRestaurant: "Tak for din anbefaling",
    snackNews: "Tak for din tilmelding",
    dish1: "Hummer Thermidor",
    dish2: "Steak w. bearnaise sauce & Baked potato",
    dish3: "Lamb chops w. parsleypesto & Summer salad",
    readMore: "Read more",
    dagsOrden: "The sustainable agenda.",
    do1:
      "Not just from the sidelines with honorable declarations of intent, passive donations or by purchasing old carbon credits.",
    do2:
      "As a network ambassador, you use your company as a catalyst to fight climate change. This simply means that you give guests or customers who want to take responsibility, the ability to do so, by offsetting the environmental impact of their meal or food purchases.",
    do3:
      "For restaurants, our calculations are based on various factors which enable us to reach an average carbon footprint per meal, (CO2DnX). This is the CO2-value that must be compensated for, in order to counteract the climate footprint of a compound meal.",
    do4:
      "The algorithm behind individual food groups is a little less transparent. Therefore, if you represent a food service company, a supermarket or a catering agency, you should contact us directly on ",
    sådanGørDu: "Become an ambassador",
    initiativTitle: "Activate your customers",
    initiativP:
      "By giving your customers / guests the ability to take an independent stand for the environment, you not only strengthen the relationships with them, but you also increase the likelihood that they will support the initiative and become climate advocates for your company.",
    initiativP2:
      "This, among other things, is due to the fact that a customer who is able to make an individual choice, feels more involved, and is therefore far more likely to remember the message, and share it with others, long after they have left your premises.",
    initiativP3: "",
    step1_cat: "Hotel & Restaurant",
    step1: 'The "Feel good factor"',
    step1_1_1:
      "Combine the luxury of dining out, with the feeling of giving something back to the planet.",
    step1_1_2:
      "A good rule of thumb is that at least 33% of your guests will be positive-minded.",
    step1_2:
      "This means that for every 300 meals you serve, approximately 100 say yes to climate-compensating. But it of course depends on how proactive you are in explaining your guests to the initiative.",
    step2_cat: "Catering",
    step2: "Sustainability to the door",
    step2_1:
      "Lunch delivery for companies, meal boxes, or food products for hotels and restaurants etc.",
    step2_1_2:
      "Now you can give your customers the option to compensate the carbon footprint of their purchases.",
    step2_2:
      "The compensation happens by first calculating the CO2 footprint of the customer's purchases (possibly via your webshop), after which he or she can choose to offset the carbon footprint, by paying a small extra amount.",
    step3_cat: "Groceries",
    step3: "Daily climate-awareness",
    step3_1:
      "Do you represent a supermarket and are considering giving your customers the option to CO2-compensate for their purchases, (possibly via your own APP), or directly at the checkout point when the bill is to be paid?",
    step3_2:
      "We make it easy and straightforward to get started… ..and you do not need to invest large sums of money and spend huge amounts of time.",
    stepNote:
      "The compensation happens by first calculating the CO2 footprint of the customer's purchases (possibly via your webshop), after which he or she can choose to offset the carbon footprint, by paying a small extra amount.",
    footerDialog1: "Suggestions for improvement",
    footerDialog2: "Unsubscribe newsletter",
    footerDialog3: "Recommend restaurant",
    textFieldKommentar: "Comments",
    foodDesuden: "Also",
    foodTilberedning: "Preparation",
    foodTotal: "Total",
    foodMængde: "Amount",
    foodProduct: "Product",
    foodIndhold: "CO2-content",
    foodIndholdPr: "CO2-content pr. unit",
    foodDisclaimer:
      "  * All figures are calculated based on data provided to us by the Food and Agriculture Authority in Denmark. From time to time there will be variables in the calculations which can change the facit, but we have tried to be as conservative as possible in our calculations.",
    food11: "Boiled lobster (250 g)",
    food12: "Sauce",
    food13: "Shallot",
    food14: "Butter",
    food15: "White wine",
    food16: "Wheat flour",
    food17: "Fish stock",
    food18: "White pepper",
    food19: "Dijon mustard",
    food110: "Whipping cream",
    food111: "Grated Emmental cheese",
    food113: "Baguette",
    food114: "Garnish",
    food115: "Lettuce",
    food116: "Fresh dill",
    food117: "Lemon",
    dish1Short: "Lobster",
    dish2Short: "Beef",
    dish3Short: "Lamb",
    food22: "Beef",
    food23: "Bearnaise sauce",
    food24: "Egg",
    food25: "Salt og pepper",
    food26: "Lemon juice",
    food27: "Estragon",
    food28: "Bearnaise essense",
    food29: "Creme fraiche",
    food210: "Baked potato",
    food211: "Seasonal vegetables",
    food212: "Butter for frying etc.",
    food31: "3 lamb chops",
    food32: "Pesto",
    food33: "Parsley",
    food34: "Garlic",
    food35: "Olive oil",
    food36: "Coarse salt",
    food37: "Salad",
    food38: "Baby spinach",
    food39: "Heart salad",
    food40: "Sesame oil",
    food41: "Grapefruit",
    food42: "Cashew nuts",
    food43: "Handful minched rosemary",
    food44: "1 Clove chopped garlic",
    food45: "Chopped parsley",
    food46: "Gated Lemon zest",
    food47: "Herb butter",
    beregning1: "Real-life estimates",
    beregning2:
      "Some meals, naturally, contain more CO2 than others, but the rule of thumb, is that you compensate for the carbon footprint of a combined meal for, (1-2 DKK / 1.5-3 SEK / 1.5-3 NOK / 0.20-0.40 EUR), whether it be an appetizer, main course or dessert. However, this may vary from restaurant to restaurant.",
    beregning3:
      "In the case of lunch delivery for businesses, meal boxes, etc., the CO2 load will often be less than average as the food is prepared in large quantities, which lowers the carbon footprint, rather than individual a la carte meals.",
    beregning4:
      'The same applies to, for example, a "Pizzaria pizza" or a burger, where the carbon foodprint can be offset for between, (0,20 - 0,40 DKK / 0,30 - 0,60 SEK / 0,30 - 0,60 NOK / 0,03 - 0,06 EUR).',
    beregning5: "3 dishes",
    beregning6:
      "Remark: Our estimates are calculated conservatively on purpose",
    net1: "",
    net2: "",
    net3: "",
    net4:
      "Due to the global sustainability trends and relevance of the time, many people wish to have an independent, positive environmental impact.",
    net5:
      "Turn your business into a catalyst in the fight for climate, in an honest, direct and exciting way.",
    eatco2idag: "EatCO2 today",
    bestil: "Order right away",
    bestilBtn: "Go to order",
    netbp1: "Strengthen your sustainable profile",
    netbp2: "Make stronger ties with your guests / customers",
    netbp3: "Get positive publicity",
    netbp4: "Attract new environmentally focused guests / customers",
    netbp5: "Make a difference in a new and exciting way",
    netbp6: "Support the UN's 17 world goals",
    formVirksomhed: "Company",
    formKontakt: "Contact person",
    email: "Email",
    tlf: "Phone number",
    kontaktMig: "Contact me",
    formDisclaimer:
      " * I accept that EatCO2 ApS (Reg. no. 38659928) may contact me via e-mail or telephone regarding the possibility of becoming part of the network.",
    option1: "Hotel & Restaurant",
    option2: "Catering",
    option3: "Food",
    osTitle: "Who we are and what we stand for",
    oseatTitle: "Who we are",
    oseat:
      "In Northern Europe, food is a very important part of everyday life.  It is the gathering point for the family during the busy week, and the opportunity to add a little extra “Hygge” for special occasions.",
    oseat2:
      "This does not necessarily mean that we should eat beef and lamb every day, but we believe that in order to preserve the social traditions behind our Northern European eating habits, we should make room to enjoy an uncompromising meal every now and then.",
    oseat3:
      "We have therefore developed a method that creates balance on the climate scale.",
    oseat4:
      'We work with a, “drop by drop” strategy", which for a modest amount, allows consumers to compensate for the carbon footprint in their meal or food purchases.',
    oseat5:
      "The amount is put into a CO2 deposit and used to plant fast-growing trees that absorb CO2 from the atmosphere. The absorbed CO2 is carefully measured and verified, and then divided into smaller portions that can be adapted to any meal or food product.",
    os1: "Our mission",
    os2:
      "We are passionate about sustainability and believe that the key to solving our climate problems must come from the individual.",
    os3:
      "Our mission is to engage consumers, to make an independent difference to the climate, and at the same time to contribute to positive social influences that support the UN's 17 world goals.",
    os3_1:
      "We have created a framework that makes it easy for consumers to make climate-conscious choices for the benefit of a more sustainable society.",
    os4: "Manifesto",
    os5:
      "Climate change and environmental challenges are our absolute priority.",
    os6:
      "We have set fundamental goals for how we can contribute by integrating sustainability into our core activities and making them essential to our long-term mission.",
    os7:
      "We believe that we achieve the most, by lifting together and that small changes from all of us can have a huge impact. We try to influence everyone related to food production, sales or preparation, to participate in the initiative and use their business as a catalyst in the fight for climate.",
    os8:
      "We strive to become a front-runner in the development of a micro-compensation system which can be adapted to all food groups and, if properly organized, can make a huge contribution to reducing CO2 emissions.",
    os9:
      "We try to influence everyone related to food production, sales or preparation, to participate in the initiative and use their business as a catalyst in the fight for climate.",
    eyTitle: "Our strategy",
    eySubtitle: "Our core values are based on credibility and transparency.",
    eySubtitle2: "They build the foundation under everything we do.",
    ey1: "Our method",
    ey2:
      "We only work with verified procedures and keep records of all CO2 offsets; each one is given a serial number and registered in the international ",
    ey3:
      "Our projects are located in Central and South America under the Plan Vivo umbrella, which is a globally recognized standard that ensures that all ethical and legal guidelines are followed in connection with planting, as well as the related CO2 compensation.",
    ey4:
      "Established in 1996, Plan Vivo is based in Edinburgh and is the oldest standard for certification of climate protection projects in the agricultural sector. In addition, Plan Vivo has won the UN's prestigious Equator Prize in 2019, which is awarded to particularly beneficial climate projects.",
    ey5:
      "Forestry projects only receive Plan Vivo's approval when they meet a number of high standards. The projects need to be based and organized locally to benefit local farming families thus creating jobs, improving infrastructure, improving health and strengthening financial conditions. These qualities make Plan Vivo one of the most credible and strongest standards in the world.",
    ey6: "Read more about Plan Vivo",
    ey7: "We make a difference together",
    ey8:
      "We are planning a strategy to create long-term income opportunities for farmers in some of the poorest parts of the world.",
    ey9:
      "It makes sense, because it is also those who are most vulnerable to the consequences of climate change.",
    ey10:
      "Our projects encourage small farming families to plant forest on the underutilized parts of their land. We then pay them continuously for their efforts as the trees begin to absorb CO2.",
    ey11:
      "In this way, farmers have a vested interest in planting and maintaining their plantations so that they achieve the most optimal growth conditions. In addition, other agricultural activities are introduced between the trees on the plantations, from which the revenue is designed to balance of the traditional agricultural season. In this way, income is spread out over the year.",
    ey12:
      "The farmers participating in our projects receive payments for their efforts during a period of 10 years. This provides solid growth and financial security over long periods.",

    faqTitle1: "The idea behind the initiative",
    faqIde1:
      "The idea behind EatCO2 is that we should hold on to the tradition of eating out.",
    faqIde2:
      "We have developed EatCO2 with the aim of combining the luxury of eating out with the feeling of giving back to the planet in an easy and transparent way.",
    faqIde3:
      "We are working with a “drop by drop strategy” which allows the customer to neutralize the CO2 emissions of their consumed meal with a modest amount which gets added to their bill.",
    faqIde4:
      "This amount is put into a CO2 pool and used to plant fast-growing trees that absorb CO2 from the atmosphere. The absorbed CO2 is carefully measured and verified, and then divided into smaller portions that can be adapted to any meal or food product.",
    faqTitle2:
      "Green profit - more than 90 percent goes directly to the benefit of the climate",
    faqGreen:
      "EatCO2 is a green profit initiative. This means that we run the company with very little administrative costs. Any surplus funds will be used to plant more trees, reinvested in new climate projects for the benefit of the environment or donated to charities in Northern Europe. A maximum of 9.25 percent of our revenue goes to administration and running costs.",
    faqTitle3: "No old carbon credits",
    faqKredit:
      "Our philosophy is to benefit the environment with new, fresh initiatives rather than leaning ourselves on someone else's green conscience. We carry out all our CO2 compensations by planting new trees; and never purchase them from existing forests.",
    faqTitle4: "We do not plant in Northern Europe",
    faqPlanter1:
      "We have deliberately chosen not to plant trees in Northern Europe, for which there are several reasons. By planting outside Northern Europe, in tropical climates, we can have a far greater impact. A warmer climate means that trees grow faster and therefore absorb more CO2 in a shorter time than eg. a pine tree in Denmark. But that we at the same time, protect the biodiversity in some of the most unique and vulnerable ecosystems we know of, is an essential parameter for us.",
    faqPlanter2:
      "In addition, we are strengthening economic growth in some of the poorest communities in the world and support 5 of the UN's 17 global goals for a sustainable future.",
    faqTitle5: "CO2 calculation",
    faqFormula1:
      "Our calculations are measured to reach a common denominator / CO2 emission for a meal, (CO2DnX). But we have set the bar high so that most meals don't emit nearly as much CO2 as we compensate for.",
    faqFormula2:
      "For example, an average meal contains approx. 6 - 8 kg. CO2, and a standard compensation covers 10 kg. In other words, we usually, overcompensate by approx. 25 - 66 percent, but this can of course vary from restaurant to restaurant.",
    faqFormula3:
      "In individual food groups, the carbon footprint also varies tremendously, which makes it difficult to identify a common denominator. We therefore overcompensate by default, at the supermarket level, by 5-10 percent across the board, whether we are talking cucumbers, bananas or tinned tuna.",
    faqFormula4: "",
    readMore2: "You can read more here",
    faqTitle6: "Climate compensation",
    faqMærke1:
      "A climate compensation, (CO2DnX), is the CO2 weight, i.e. how much CO2 which needs to be offset in order to match the climate footprint in a specific food product or a whole meal.",
    faqMærke2:
      "Climate compensations have different weights and values ​​in relation to what is to be compensated. A composite meal for example, will often have a greater carbon footprint than a single commodity product, just as meat has a greater CO2 emission than vegetables.",
    faqTitle7: "Our food is the key to addressing climate change",
    faqNøgle:
      "If we are to stop manmade climate change, an average Northern European has to reduce their environmental impact by at least 80 percent over the next 20 years.",
    faqNøgle2:
      "Since we know that over a fifth of our CO2 emissions come from our food, this is where we can make a noticeable and very effective difference.",
    faqNøgle3:
      "Of course, this requires that we start doing things in entirely new ways, but especially companies that deal with food production and preparation can pave the way for a change on a consumer level.",
    faqTitle8: "CO2 in our diet",
    faqKost1:
      "In Denmark, Sweden, Norway and Finland we combined generate approximately 80 million tons of CO2 annually as a direct result of our food consumption. This corresponds to roughly 20 percent of the total emissions and is about twice as much as all passenger cars in the four countries combined emit annually.",
    faqKost2:
      "We consume a lot of meat in Northern Europe, and although there is no doubt vegetarian diet is better for the climate than food originating from animals, in the plant-based diet nutrients are usually also grams for grams less. Therefore, more plant-based foods will have to be consumed in order to arrive at the same nutritional content.",
    faqKost3:
      'Hence, one cannot make a "tomato / meatball" comparison when you measure the carbon footprint between vegetarian and animal foods. Instead, we have to look at the overall imprint of several plant-based foods so that the nutritional content is equal to the animal food.',
    faqKost4:
      "Whether you are a vegan, vegetarian or meat eater, there should be room for all of us, as long as we consider the environment! Choose seasonally, minimize food wastage and keep an eye on your CO2 levels.",
    faqTitle9: "CO2 and alcohol",
    faqa1:
      "Alcohol as a group that ranks fourth among foods’ carbon footprint in Northern Europe and should not be overlooked. The environmental impact of the various types of alcohol is based on the ingredients of the alcohol; grapes for wine, sugar cane for rum, barley for beer, apples for cider.",
    faqa2:
      "The environmental impact is, among other things, measured by the amount of fertilizer and water needed and whether cultivation inhibits biodiversity. This can for example be the case if forests are cut down to give way to new agricultural land.",
    faqa3:
      "Many of the major alcohol producers have introduced a number of measures in relation to using clean energy and better cooling facilities, as well as reducing water consumption. These measures although reduce CO2 emissions in production, far from eliminate them.",
    faqTitle10: "The Paris Agreement",
    faqP:
      "Northern Europe has one of the world's largest climate prints measured per person per year.",
    faqP2:
      "We each emit an average of 10 tons of CO2 a year and one fifth comes from the food we eat. But we also have a huge debt to the climate as a result of industrial welfare development. That is why we believe that it is our collective responsibility to be a front-runner in sustainable development by supporting new climate friendly initiatives.",
    faqP3:
      "In 2015, the UN countries signed a climate agreement that obliges us to keep the temperature rise below 2 degrees and preferably 1.5 degrees relative to the pre-industrialization level. Already, the temperature has increased by approx. 1 degree, action is urgent.",
    faqP4:
      "Every two years, member states must report to the UN on how to achieve the set climate goals and our work to reduce CO2 emissions.",
    faqP5:
      "Finally, wealthy countries must help the poor to finance the transition to green energy. From 2020, 100 billion dollars will be generated annually to support the cause.",
    faqTitle11: "We support UN's global goals",
    faqFn1:
      "The UN's 17 World Goals for a sustainable future were adopted by the member states in 2015. The World Goals range widely from civil rights to a clean marine environment.",
    faqFn2:
      "By joining the EatCO2 network, you are automatically working for World Goal 1, which aims to eradicate poverty, World Goal 11 to support a sustainable society and innovation, World Goal 13 to fight climate change, World Goal 15 to protect life on land, and World Goal 17, where the goal is to foster global climate partnerships.",
    readMoreVerden: "Read more about the world goals",
    fvTitle: "Food",
    fvSub1: "Groceries - Northern Europeans think about sustainability",
    fv1: "The food sold in the supermarket has a major environmental impact.",
    fv2:
      "As the climate gradually has become an important part of the daily lives for many, sustainability in relation to own purchases also plays an increasing role. A study by “Agriculture and Food” in Denmark, established that in 2019 two-thirds of Northern Europeans think about sustainability when they shop for food and drink.",
    fv3:
      "This conveys that many people try to educate themselves in relation to the climate impact of the individual goods, but the calculations are complicated and often surprising.",
    fvSub2: "Vegetables - an intricate piece of math",
    fv5:
      "Taking vegetables as an example, during certain parts of the year, vegetables can have a greater carbon footprint when produced in Northern Europe than in Southern Europe. This is because in the Nordic countries we often grow vegetables in greenhouses to have fresh produce all year round. However, a greenhouse uses an enormous amount energy for light and heat, which results in a far greater climate impact than when the vegetables are grown under open sky abroad and then transported to Northern Europe by truck.",
    fv6:
      "The explanation is that transport only constitutes a small part of the product's total impact on the environment. Production itself is far more important. In fact, production accounts for up to 80 percent of the total environmental impact, compared to transport of only 15 percent and packaging the last 5 percent.",
    fvOvergang: "Two out of three people want to eat more sustainable.",
    fvSub3: "Our solution",
    fv7:
      'As the population grows, the need for food increases, which puts increased pressure on the climate. That is why we have "geared" our CO2 tool, so that food producers can also participate in the fight for the climate.',
    fv8:
      "In practice, this means that we enable you to neutralize the carbon footprint of your food produce all the way down to a few grams of CO2.",
    call: "Receive our newsletter and support the sustainability",
    call2:
      "The goal with our newsletter is to share information about sustainable trends, entrepreneurship and products with the hope that influcence people to implement environmentally friendly measures in the day to day life",
    call3:
      "You will maximum receive 1 email for each quarter and you can unsubscribe by clicking the link in the footer",
    call4: "Thank you for your help",
    name: "Name",
    hand: "Terms of purchase",
    hand1:
      'EatCo2 (Reg. DK30959834), is a Danish "GreenProfit" corporation engaged in planting trees worldwide, in order to compensate for CO2 emissions in the food industry.',
    hand2:
      "EatCo2 allows restaurants and food manufacturers to offset their environmental impact through donations or payments for micro-compensation of groceries or whole meals, by implementing forestry that results in the absorption of greenhouse gases from the atmosphere.",
    hand3: "Payment",
    hand4:
      "Purchase of goods or services through EatCO2 is subject to VAT (25%), unless the customer is exempt from VAT, such as making the purchase from Sweden, Norway or Finland.",
    hand5:
      "Unless otherwise agreed, invoices are due 7 calendar days after invoicing. Payment is made to the account specified on the invoice.",
    hand6:
      "All bank charges associated with the payment must be borne by the buyer and must not lower the total invoiced amount. Delayed payments are subject to Danish interest rate law.",
    hand7: "Use of labels, names and logos",
    hand8:
      "When entering into a partnership with EatCO2, a non-exclusive, non-transferable right is given to the customer in order to use EatCO2's name and logo for publication purposes.",
    hand9:
      "The Customer may not make any alterations or copies of the EatCO2 brand, stamps, labels, logos or material in general, unless otherwise agreed. Customer is aware that EatCO2 will enforce its copyright and trademark rights to the fullest extent of the law.",
    hand10:
      "EatCO2 may use data for statistical or scientific purposes. In addition, EatCO2 may name the customer on an EatCO2 website or in other media, unless the parties have agreed otherwise.",
    hand11:
      "The right to use the EatCO2 logos, labels etc. terminates in the event of damage caused to EatCO2's reputation or if it is deemed that the customer's actions are in conflict with the values ​​and objectives of EatCO2.",
    hand12:
      "At the end of the collaboration, materials and licenses, (especially logos and labels), may no longer be used and must be returned if necessary. This does not include quoting the collaboration for reference purposes.",
    hand13: "Customer data",
    hand14:
      "EatCO2 processes data received from the customer confidentially in accordance with GDPR law.",
    hand15:
      "However, EatCO2 may use data for statistical, scientific or advertising purposes. If such data is disclosed, we anonymize the customer name and will only publish it with the express consent given by the customer.",
    hand16:
      "EatCO2 owns the copyright to all statistical reports generated by EatCO2, based on the data we collect.",
    hand17: "Guidelines",
    hand18:
      "EatCO2 calculates our climate impact based on information made available by our partners. Although the calculation bases are considered credible, EatCO2 is not responsible for inaccurate or incomplete data or for calculation errors.",
    hand19:
      "Furthermore, EatCO2 is not liable for damages due to factors beyond EatCO2's control, including but not limited to natural disasters, political unrest, war, terrorism, lack of raw materials, or restrictions on the use of energy or failure of the technical infrastructure.",
    hand20: "Tax deduction & Marketing",
    hand21:
      "In connection with the purchase of “micro-compensations”, (Climate stamps), the customer might be able to deduct the entire amount as operating expenses.",
    hand22:
      "It is however up to the customer to verify this, possibly after consultation with an accountant, whether the amount can be deducted as an operating expense (i.e. advertising expense).",
    hand23: "Appointment Changes",
    hand24:
      "EatCO2 may want to change these terms. Any alterations will be published on the website and / or sent to the customer.",
    hand25:
      "Subsequent changes to the terms will become an integral part of the agreement if the customer does not object to them in writing within 30 days of the terms of trade being published online and / or sent to the customer.",
    hand26:
      "Unless otherwise agreed, any collaboration with EatCO2 is governed by Danish law",
    varemaerke: "Trademark",
    tsk: "tsp",
    spsk: "tbsp",
    fed: "clove",
  },
};
