import React from "react";
import {
  Typography,
  Grid,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Paper,
  Tabs,
  Tab,
  ExpansionPanelSummary,
  useMediaQuery,
} from "@material-ui/core";
import Spacer from "../components/Layout/Spacer";
import img from "../assets/icons/CO2_beregning_-_Sådan_udregned_CO2-min.jpg";
import TwoColumnGrid from "../components/Layout/TwoColumnGrid";
import { Alert } from "@material-ui/lab";

import meat from "../assets/icons/Estimater_fra_det_virkelige_liv-min.jpg";

import hummer from "../assets/svgs/lobster.png";
import okse from "../assets/svgs/cow.png";
import lamb from "../assets/svgs/sheep.png";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useTranslation from "../hooks/useT";

export default function Beregning() {
  const [activeTab, setActiveTab] = React.useState(0);
  const [showMore, setShowMore] = React.useState(null);

  const t = useTranslation();

  const data = [
    {
      img: hummer,
      title: t.dish1,
      items: [
        {
          name: t.food11,
          s: "5.050 kg. CO2",
          e: "20,2 CO2 pr. kg",
          t: [],
        },
        {
          name: t.food12,
          s: "777,5 g. CO2",
          e: "",
          t: [
            {
              name: `1/2 ${t.food13}`,
              s: "5 g",
              e: "400 g",
            },
            {
              name: `5 g ${t.food14}`,
              s: "53 g",
              e: "10,6 kg",
            },
            {
              name: `75 ml ${t.food15}`,
              s: "180 g",
              e: "2,4 kg",
            },
            {
              name: `75 ml ${t.food110}`,
              s: "397,5 g",
              e: "5,3 kg",
            },
            {
              name: `12,5 g ${t.food111}`,
              s: "132 g",
              e: "6,6 kg",
            },
            {
              name: `1/2 ${t.tsk}. ${t.food19}\n1/2 ${t.tsk}. ${t.food16}\n1 ${t.spsk}. ${t.food17}\n${t.food18}`,
              s: "10 g",
              e: "1 kg",
            },
          ],
        },
        {
          name: t.foodDesuden,
          s: "291.5 g CO2",
          e: "",
          t: [
            {
              name: `15 g ${t.food14}`,
              s: "159 g",
              e: "10,6 kg",
            },
            {
              name: `12,5 g ${t.food111}`,
              s: "82,5 g",
              e: "6,6 kg",
            },
            {
              name: `100 g ${t.food113}`,
              s: "50 g",
              e: "1 kg",
            },
          ],
        },
        {
          name: t.foodTilberedning,
          s: "2 kg CO2  ",
          e: "",
        },
        {
          name: t.foodTotal,
          s: "8.119 kg CO2",
          e: "",
        },
      ],
    },
    {
      img: okse,
      title: t.dish2,
      items: [
        {
          name: `300 g ${t.food22}`,
          s: "4.170 kg CO2",
          e: "13,9 kg",
        },
        {
          name: t.food23,
          s: "757,5 g CO2",
          e: "",
          t: [
            {
              name: `50 g ${t.food14}`,
              s: "530 g",
              e: "10,6 kg",
            },
            {
              name: `1 ½ ${t.food24}`,
              s: "187,5 g",
              e: "2 kg",
            },
            {
              name: `${t.food25}\n1 ${t.tsk} ${t.food26}\n${t.food27}\n1/2 ${t.tsk} ${t.food28}`,
              s: "40g",
              e: "1 kg",
            },
          ],
        },
        {
          name: t.foodDesuden,
          s: "880,8 CO2",
          t: [
            {
              name: `2 ${t.spsk} ${t.food29} (29 g)`,
              s: "150,8 g",
              e: "5,2 kg",
            },
            {
              name: `250 g ${t.food210}`,
              s: "50 g",
              e: "200 g",
            },
            {
              name: `30 g ${t.food211}`,
              s: "150 g",
              e: "500 g",
            },
            {
              name: `50 g ${t.food212}`,
              s: "530 g",
              e: "10,6 kg",
            },
          ],
        },
        {
          name: t.foodTilberedning,
          s: "2 kg CO2",
          e: "",
        },
        {
          name: t.foodTotal,
          s: "7.803,3 kg CO2",
        },
      ],
    },
    {
      img: lamb,
      title: t.dish3,
      items: [
        {
          name: `${t.food31} (250 g)`,
          s: "5.350 kg",
          e: "21,4 kg",
        },
        {
          name: t.food32,
          s: "611 g",
          t: [
            {
              name: `57 g ${t.food33}`,
              s: "28,5 g",
              e: "1 kg",
            },
            {
              name: `2 ${t.fed} ${t.food34} (10 g)`,
              s: "10 g",
              e: "1 kg",
            },
            {
              name: `1/2 dl ${t.food35}`,
              s: "532,5 g",
              e: "10,65 kg",
            },
            {
              name: t.food117,
              s: "30 g",
              e: "600 g",
            },
            {
              name: t.food36,
              s: "10 g",
              e: "1 kg",
            },
          ],
        },
        {
          name: t.food47,
          s: "295 g",
          t: [
            {
              name: `25 g ${t.food14}`,
              s: "265 g",
              e: "10,6 kg",
            },
            {
              name: `25 g ${t.food43}`,
              s: "12,5 g",
              e: "500 g",
            },
            {
              name: t.food44,
              s: "5 g",
              e: "1 kg",
            },
            {
              name: `25 g ${t.food45}`,
              s: "12,5 g",
              e: "500 g",
            },
            {
              name: `1/2 ${t.tsk}. ${t.food46}`,
              s: "",
              e: "",
            },
          ],
        },
        {
          name: t.food37,
          s: "224.5 g",
          t: [
            {
              name: `100 g ${t.food38}`,
              s: "50 g",
              e: "500 g",
            },
            {
              name: `50 g ${t.food39}`,
              s: "25 g",
              e: "500 g",
            },
            {
              name: `1 ${t.spsk}. ${t.food40}`,
              s: "25,5 g",
              e: "1,8 kg",
            },
            {
              name: t.food25,
              s: "10 g",
              e: "1 kg",
            },
            {
              name: `1/2 ${t.food41}`,
              s: "60 g",
              e: "600 g",
            },
            {
              name: `30 g ${t.food42}`,
              s: "54 g",
              e: "1,8 kg",
            },
          ],
        },
        {
          name: t.foodTilberedning,
          s: "2 kg. CO2",
        },
        {
          name: t.foodTotal,
          s: "8.474,5 kg. CO2",
        },
      ],
    },
  ];

  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <div className="container">
      <Spacer value={5} />
      <TwoColumnGrid>
        <div>
          <Typography variant="h4" gutterBottom>
            {t.beregning1}
          </Typography>
          <Typography gutterBottom>{t.beregning2}</Typography>
        </div>
        <img
          src={img}
          alt=""
          style={{ height: "35vh", objectFit: "contain", width: "100%" }}
        />
      </TwoColumnGrid>
      <Spacer value={5} />
      <Spacer value={5} />
      <div>
        <TwoColumnGrid alignItems="stretch">
          <div style={{ textAlign: "center" }}>
            <img src={meat} height={300} alt="" className="big-img" />
          </div>
          <div>
            <Typography gutterBottom paragraph>
              {t.beregning3}
            </Typography>
            <Typography gutterBottom>{t.beregning4}</Typography>
          </div>
        </TwoColumnGrid>
        <Spacer value={5} />
        <Typography variant="h4" gutterBottom paragraph>
          {t.beregning5}
        </Typography>
        <Alert severity="info">{t.beregning6}</Alert>
        <Spacer value={2} />
        <Paper>
          <Grid container spacing={4} style={{ padding: 20 }}>
            <Grid item xs={12} sm={2}>
              <Tabs
                value={activeTab}
                onChange={(e, value) => setActiveTab(value)}
                centered={isMobile}
                orientation={isMobile ? "horizontal" : "vertical"}
              >
                <Tab
                  label={t.dish1Short}
                  icon={<img src={hummer} height={50} alt="" />}
                  style={{ padding: "30px 0" }}
                ></Tab>
                <Tab
                  icon={<img src={okse} height={50} alt="" />}
                  label={t.dish2Short}
                  style={{ padding: "30px 0" }}
                ></Tab>
                <Tab
                  icon={<img src={lamb} height={45} alt="" />}
                  label={t.dish3Short}
                  style={{ padding: "30px 0" }}
                ></Tab>
              </Tabs>
            </Grid>
            <Grid item sm={10} xs={12} style={{ overflow: "auto" }}>
              <Typography gutterBottom variant="h6">
                {data[activeTab].title}
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold" }}>
                      {t.foodProduct}
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      {t.foodIndhold}
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }} align="right">
                      {t.foodIndholdPr}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data[activeTab].items.map((dataEl, index) => {
                    const hasMore = dataEl.t && dataEl.t.length > 0;
                    return (
                      <>
                        <TableRow
                          key={`body_${index}`}
                          onClick={() => {
                            if (index === showMore) {
                              setShowMore(null);
                            } else {
                              setShowMore(index);
                            }
                          }}
                        >
                          <TableCell>
                            <ExpansionPanelSummary
                              expandIcon={hasMore ? <ExpandMoreIcon /> : null}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography
                                className="text--primary"
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                {dataEl.name}
                              </Typography>
                            </ExpansionPanelSummary>
                          </TableCell>
                          <TableCell>{dataEl.s}</TableCell>
                          <TableCell align="right">{dataEl.e}</TableCell>
                        </TableRow>
                        {showMore === index &&
                          dataEl.t &&
                          dataEl.t.map((t) => (
                            <TableRow className="fade">
                              <TableCell>
                                <Typography style={{ whiteSpace: "pre" }}>
                                  {t.name}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{t.s}</Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography>{t.e}</Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                      </>
                    );
                  })}
                </TableBody>
              </Table>
              <Spacer />
              <Typography variant="caption">{t.foodDisclaimer}</Typography>
            </Grid>
          </Grid>
        </Paper>
        <Spacer value={5} />
      </div>
    </div>
  );
}
