import React from "react";
import { Typography, Button } from "@material-ui/core";
import Spacer from "../components/Layout/Spacer";
import TwoColumnGrid from "../components/Layout/TwoColumnGrid";
import womenShopping from "../assets/icons/foerste_billede_foedevarer-min.jpg";
import tomater from "../assets/icons/Fødevare_-_Grønsager-min.jpg";
import { Link } from "react-router-dom";
import Overgang from "../components/Overgang";
import useTranslation from "../hooks/useT";

export default function Food() {
  const {
    fvTitle,
    fvSub1,
    fv1,
    fv2,
    fv3,
    fvSub2,
    fv5,
    fv6,
    fvOvergang,
    fvSub3,
    fv7,
    fv8,
    netTitle,
  } = useTranslation();

  return (
    <div className="gap">
      <div className="container">
        <TwoColumnGrid alignItems="center">
          <div>
            <Typography variant="h3" gutterBottom className="text--secondary">
              {fvTitle}
            </Typography>
          </div>
          <div className="height--placeholder"></div>
        </TwoColumnGrid>
        <Spacer value={5} />
        <TwoColumnGrid>
          <div>
            <Typography variant="h4" gutterBottom>
              {fvSub1}
            </Typography>
            <Typography variant="h6" gutterBottom>
              {fv1}
            </Typography>
            <Typography gutterBottom>{fv2}</Typography>
            <Spacer />
            <Typography gutterBottom>{fv3}</Typography>
          </div>
          <div style={{ textAlign: "center" }}>
            <img src={womenShopping} height={400} className="big-img" alt="" />
          </div>
        </TwoColumnGrid>
        <Spacer value={5} />
        <TwoColumnGrid>
          <div style={{ textAlign: "center" }}>
            <img src={tomater} height={300} alt="" />
          </div>
          <div>
            <Typography gutterBottom variant="h4">
              {fvSub2}
            </Typography>
            <Typography gutterBottom>{fv5}</Typography>
            <Typography gutterBottom>{fv6}</Typography>
          </div>
        </TwoColumnGrid>
      </div>
      <Overgang>
        <Typography variant="h3">{fvOvergang}</Typography>
      </Overgang>
      <div className="container">
        <Spacer value={5} />
        <Typography gutterBottom variant="h4">
          {fvSub3}
        </Typography>
        <div>
          <Typography gutterBottom>{fv7}</Typography>
          <Typography gutterBottom className="semi-bold text--primary">
            {fv8}
          </Typography>
          <Button
            color="primary"
            variant="contained"
            component={Link}
            to="/network"
            size="large"
          >
            {netTitle}
          </Button>
        </div>
      </div>
    </div>
  );
}
