import React from "react";
import { Typography, Button, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import Spacer from "../components/Layout/Spacer";

import TwoColumnGrid from "../components/Layout/TwoColumnGrid";
import waiter from "../assets/icons/billede_faq_12-min.jpg";
import co2kost from "../assets/icons/FAQ_-_CO2_i_kosten-min.jpg";

import i1 from "../assets/icons/fn/i1.png";
import i3 from "../assets/icons/fn/i3.png";
import i4 from "../assets/icons/fn/i4.png";
import i5 from "../assets/icons/fn/i5.png";
import i6 from "../assets/icons/fn/i6.png";

import i1_en from "../assets/icons/fn/i1_en.png";
import i3_en from "../assets/icons/fn/i3_en.png";
import i4_en from "../assets/icons/fn/i4_en.png";
import i5_en from "../assets/icons/fn/i5_en.png";
import i6_en from "../assets/icons/fn/i6_en.png";

import klimamærke from "../assets/icons/klimamærke.png";
import useTranslation from "../hooks/useT";
import { useMainContext } from "../context/Context";

export default function FaqItemsV3() {
  const { selectedLanguage } = useMainContext();
  const t = useTranslation();

  let icons = [i1, i3, i5, i4, i6];
  if (selectedLanguage === "en") {
    icons = [i1_en, i3_en, i5_en, i4_en, i6_en];
  }

  return (
    <div className="gap">
      <div className="container">
        <TwoColumnGrid alignItems="center">
          <div>
            <Typography gutterBottom paragraph variant="h4">
              {t.faqTitle2}
            </Typography>
            <Typography gutterBottom>{t.faqGreen}</Typography>
          </div>
          <div className="text--center">
            <img src={waiter} alt="" className="big-img" height={400} />
          </div>
        </TwoColumnGrid>
        <Spacer value={5} />
        <Typography variant="h4" gutterBottom paragraph>
          {t.faqTitle3}
        </Typography>
        <Typography gutterBottom>{t.faqKredit}</Typography>
        <Spacer value={5} />
        <Typography variant="h4" gutterBottom>
          {t.faqTitle4}
        </Typography>
        <Typography gutterBottom>{t.faqPlanter1}</Typography>
        <Typography gutterBottom>{t.faqPlanter2}</Typography>
        <Spacer value={5} />
        <Typography variant="h4" gutterBottom>
          {t.faqTitle5}
        </Typography>
        <Typography gutterBottom>
          <span className="text--primary semi-bold">{t.faqFormula1}</span>{" "}
          {t.faqFormula2}
        </Typography>
        <Typography gutterBottom>
          <span className="text--primary semi-bold">{t.faqFormula3} </span>{" "}
          {t.faqFormula4}
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          size="large"
          component={Link}
          to="/beregning"
        >
          {t.readMore2}
        </Button>
      </div>
      <Spacer value={5} />
      <section className="curved">
        <div className="container">
          <Typography variant="h4" gutterBottom>
            {t.faqTitle6}
          </Typography>
          <Typography gutterBottom variant="h6">
            {t.faqMærke1}
          </Typography>
          <Typography
            gutterBottom
            className="text--primary semi-bold"
            variant="h6"
          >
            {t.faqMærke2}
          </Typography>
        </div>
        <img
          src={klimamærke}
          alt="klimamærke"
          style={{
            position: "absolute",
            right: 100,
            bottom: -35,
            height: 200,
            width: 200,
            border: "solid var(--bg) 2px",
            borderRadius: 100,
          }}
        />
      </section>
      <Spacer value={5} />
      <div className="container">
        <Typography variant="h4" gutterBottom>
          {t.faqTitle7}
        </Typography>
        <Typography gutterBottom>
          <span className="text--primary semi-bold">{t.faqNøgle}</span>{" "}
          {t.faqNøgle2}
        </Typography>
        <Typography gutterBottom>{t.faqNøgle3}</Typography>
        <Spacer value={5} />
        <Typography variant="h4" gutterBottom>
          {t.faqTitle8}
        </Typography>
        <Typography variant="h6" gutterBottom>
          {t.faqKost1}
        </Typography>
      </div>
      <div className="container">
        <TwoColumnGrid alignItems="center">
          <div>
            <Typography gutterBottom>{t.faqKost2}</Typography>
            <Typography gutterBottom>{t.faqKost3}</Typography>
            <Typography gutterBottom>{t.faqKost4}</Typography>
          </div>
          <div className="text--center">
            <img src={co2kost} alt="" height={400} className="big-img" />
          </div>
        </TwoColumnGrid>
        <Spacer value={5} />
        <Typography variant="h4" gutterBottom paragraph>
          {t.faqTitle9}
        </Typography>
        <Typography gutterBottom>{t.faqa1}</Typography>
        <Typography gutterBottom>{t.faqa2}</Typography>
        <Typography gutterBottom>{t.faqa3}</Typography>
        <Spacer value={5} />
        <Typography variant="h4" gutterBottom>
          {t.faqTitle10}
        </Typography>
        <Typography variant="h6" gutterBottom>
          {t.faqP}
        </Typography>
        <Typography gutterBottom>{t.faqP2}</Typography>
        <Typography gutterBottom>{t.faqP3}</Typography>
        <Typography gutterBottom>{t.faqP4}</Typography>
        <Typography gutterBottom>{t.faqP5}</Typography>
        <Spacer value={5} />
        <Typography variant="h4" gutterBottom>
          {t.faqTitle11}
        </Typography>
        <Typography gutterBottom>{t.faqFn1}</Typography>
        <Typography gutterBottom>{t.faqFn2}</Typography>
        <Spacer value={3} />
        <Grid container justify="flex-start">
          {icons.map((icon) => (
            <Grid item style={{ marginRight: 20 }}>
              <img src={icon} height={150} alt="" />
            </Grid>
          ))}
        </Grid>
        <Spacer value={3} />
        <Button
          variant="outlined"
          color="primary"
          onClick={() =>
            window.open("https://www.verdensmaalene.dk/fakta/verdensmaalene")
          }
        >
          {t.readMoreVerden}
        </Button>
      </div>
      <Spacer value={3} />
    </div>
  );
}
