import React from "react";
import { Typography } from "@material-ui/core";
import Spacer from "../components/Layout/Spacer";

export default function Privacy() {
  return (
    <div className="gap">
      <div className="container">
        <Typography variant="h3" gutterBottom>
          Privatlivspolitik
        </Typography>
        <Typography variant="h5" gutterBottom>
          Introduktion
        </Typography>
        <Typography gutterBottom>
          Når du besøger vores website eatco2.com og eatco2.dk indsamles der
          oplysninger om dig, som bruges til at tilpasse og forbedre vores
          indhold og til at øge værdien af de annoncer, der vises på siden.
        </Typography>
        <Typography gutterBottom>
          Hvis du ikke ønsker, at der indsamles oplysninger, bør du slette dine
          cookies og undlade videre brug af websitet.
        </Typography>
        <Typography gutterBottom>
          Nedenfor har vi uddybet, hvilke informationer der indsamles, deres
          formål og hvilke tredjeparter, der har adgang til dem.
        </Typography>
        <Spacer value={3} />
        <Typography variant="h5" gutterBottom>
          Cookies
        </Typography>
        <Typography gutterBottom>
          Websitet anvender ”cookies”, der er en tekstfil, som gemmes på din
          computer, mobil el. tilsvarende med det formål at genkende den, huske
          indstillinger, udføre statistik og målrette annoncer.
        </Typography>
        <Typography gutterBottom>
          Cookies kan ikke indeholde skadelig kode som f.eks. virus.
        </Typography>
        <Typography gutterBottom>
          Det er muligt at slette eller blokere for cookies.
        </Typography>
        <Typography gutterBottom>
          Hvis du sletter eller blokerer cookies, vil annoncer kunne blive
          mindre relevante for dig. Du kan desuden risikere at websitet ikke
          fungerer optimalt samt at der er indhold, du ikke kan få adgang til.
        </Typography>
        <Spacer value={3} />
        <Typography variant="h5" gutterBottom>
          Personoplysninger
        </Typography>
        <Typography gutterBottom>
          Personoplysninger er alle slags informationer, der i et eller andet
          omfang kan henføres til dig. Når du benytter vores website, indsamler
          og behandler vi en række sådanne informationer.
        </Typography>
        <Typography gutterBottom>
          Det sker f.eks. ved alm. tilgang af indhold, hvis du tilmelder dig
          vores nyhedsbrev eller registrerer dig som bruger af services via
          websitet.
        </Typography>
        <Typography gutterBottom>
          Vi indsamler og behandler typisk følgende typer af oplysninger: Et
          unikt ID og tekniske oplysninger om din computer, tablet eller
          mobiltelefon, dit IP-nummer, geografisk placering, samt hvilke sider
          du klikker på (interesser).
        </Typography>
        <Typography gutterBottom>
          I det omfang du selv giver eksplicit samtykke hertil og selv indtaster
          informationerne behandles desuden: Navn, telefonnummer, e-mail og
          adresse. Det vil typisk være i forbindelse med at du udfylder en
          formular på websitet.
        </Typography>
        <Spacer value={3} />
        <Typography variant="h5" gutterBottom>
          Sikkerhed
        </Typography>
        <Typography gutterBottom>
          Vi har truffet tekniske og organisatoriske foranstaltninger mod, at
          dine oplysninger hændeligt eller ulovligt bliver slettet,
          offentliggjort, fortabt, forringet eller kommer til uvedkommendes
          kendskab, misbruges eller i øvrigt behandles i strid med lovgivningen.
        </Typography>
        <Spacer value={3} />
        <Typography variant="h5" gutterBottom>
          Formål
        </Typography>
        <Typography gutterBottom>
          Oplysningerne bruges til at identificere dig som bruger og vise dig de
          annoncer, som vil have størst sandsynlighed for at være relevante for
          dig, at registrere dine køb og, samt at kunne levere de services, du
          har efterspurgt, som f.eks. at fremsende et nyhedsbrev eller sætte dig
          i forbindelse med leverandører du selv har efterspurgt at blive
          kontaktet af.
        </Typography>
        <Typography gutterBottom>
          Herudover anvender vi oplysningerne til at optimere vores services og
          indhold.
        </Typography>
        <Spacer value={3} />
        <Typography gutterBottom variant="h5">
          Periode for opbevaring
        </Typography>
        <Typography gutterBottom>
          Oplysningerne opbevares i det tidsrum, der er tilladt i henhold til
          lovgivningen, og vi sletter dem, når de ikke længere er nødvendige.
        </Typography>
        <Typography gutterBottom>
          Perioden afhænger af karakteren af oplysningen og baggrunden for
          opbevaring. Det er derfor ikke muligt at angive en generel tidsramme
          for, hvornår informationer slettes.
        </Typography>
        <Spacer value={3} />
        <Typography gutterBottom variant="h5">
          Videregivelse af oplysning
        </Typography>
        <Typography gutterBottom>
          Data om din brug af websitet, hvilke annoncer, du modtager og evt.
          klikker på, geografisk placering, køn og alderssegment m.v.
          videregives ikke til tredjeparter, med mindre du selv har givet
          samtykke til dette.
        </Typography>
        <Typography gutterBottom>
          Vi benytter herudover en række tredjeparter til opbevaring og
          behandling af data. Disse behandler udelukkende oplysninger på vores
          vegne og må ikke anvende dem til egne formål.
        </Typography>
        <Typography gutterBottom>
          Videregivelse af personoplysninger som navn og e-mail m.v. vil kun
          ske, hvis du giver samtykke til det. Vi anvender kun databehandlere i
          EU eller i lande, der kan give dine oplysninger en tilstrækkelig
          beskyttelse.
        </Typography>
        <Spacer value={3} />
        <Typography gutterBottom variant="h5">
          Indsigt og klager
        </Typography>
        <Typography gutterBottom>
          Du har ret til at få oplyst, hvilke personoplysninger, vi behandler om
          dig. Du kan desuden til enhver tid gøre indsigelse mod, at oplysninger
          anvendes. Du kan også tilbagekalde dit samtykke til, at der bliver
          behandlet oplysninger om dig. Du har til en hver tid ret til at
          tilbagekalde dit samtykke, ved at klikke på knappen ”tilbagekald mit
          samtykke” nederst på websitet. Og udfylde formularen. Hvis du vil
          klage over vores behandling af dine personoplysninger, har du også
          mulighed for at tage kontakt til Datatilsynet.
        </Typography>
        <Spacer value={3} />
        <Typography gutterBottom variant="h5">
          Udgiver
        </Typography>
        <Typography gutterBottom>Websitet ejes og publiceres af:</Typography>
        <Typography gutterBottom>EatCO2 ApS</Typography>
        <Typography gutterBottom>
          Taastrupgårdsvej 20 2660 Taastrup, Danmark
        </Typography>
        <Typography gutterBottom>Telefon: 70 55 55 80</Typography>
        <Typography gutterBottom>Email: kontakt@eatco2.dk</Typography>
      </div>
    </div>
  );
}
