import React from "react";

import { Typography, Button } from "@material-ui/core";
import { Link } from "react-router-dom";

import Spacer from "../components/Layout/Spacer";

import { ImageOutlined } from "@material-ui/icons";

import faq6 from "../assets/undraw_Calculator_0evy.svg";
import planterTraeer from "../assets/CommuniTree_Limay_Nicaragua/CommuniTree Limay_Nicaragua_12.jpg";

export const iconStyle = {
  width: 30,
  height: 30,
};

const AnswerOne = () => (
  <>
    <Typography>
      EatCo2 er et GreenProfit initiativ, hvilket betyder at vi bruger alle
      overskydende midler for at beskytte klimaet. Enten vil overskuddet blive
      brugt til at plante flere træer, eller re-investeret i nye klimaprojekter
      til gavn for miljøet. 9.25 % af vores omsætning, går til dækning af
      administration og interne omkostninger.
    </Typography>
  </>
);

const AnswerTwo = () => (
  <>
    <Typography gutterBottom>
      Et Co2-certifikat er et bevis, der repræsenterer mængden af Co2 der i
      løbet af året bliver kompenseret for i din restaurant.
    </Typography>
    <Typography>
      En gang i kvartalet tæller vi alle dine Co2-kompensationer sammen, og
      opdaterer dit Co2-certifikat. Herudover deltager du i den årlige kåring af
      Danmarks mest bæredygtige restaurant.
    </Typography>
    <Spacer value={2} />
  </>
);

const AnswerThree = () => (
  <>
    <Typography gutterBottom>
      Vi har bevist valgt ikke at plante træer i Danmark fordi vi har en større
      miljøpåvirkning i varmere klima.
    </Typography>
    <Typography gutterBottom>
      Det skyldes flere årsager, men bla. at et træ vokser hurtigere når det er
      varmt og derfor absorbere mere Co2 end eks. et grantræ i Danmark.
    </Typography>
    <Typography gutterBottom>
      Herudover, når vi planter i udsatte områder, styrker vi økonomisk vækst i
      nogle af de fattigste samfund i verden, og beskytter samtidigt nogle af de
      mest biodiverse og unikke økosystemer vi kender til.
    </Typography>
  </>
);

const AnswerFour = () => (
  <>
    <Typography gutterBottom>
      Transparens og Troværdighed er kerneværdier hos EatCo2 og bygger fundament
      under alt hvad vi gør.
    </Typography>
    <Typography gutterBottom>
      Vi arbejder derfor kun med verificerede procedure, og føre protokol over
      alle CO2 kompensationer, som hver får et serienummer der registreres i det
      internationale, ”Markit-register”.
    </Typography>
    <Button
      onClick={() =>
        (window.location.href =
          "https://ihsmarkit.com/products/environmental-registry.html")
      }
      variant="contained"
      color="primary"
      className="faq-btn"
    >
      Klik her for mere information
    </Button>
    <Typography gutterBottom>
      Vores projekter er opført i Mellem- og Sydamerika, under ”Plan Vivo”
      paraplyen, hvilket er en global annerkendt standard, der sikre at alle
      etiske og juridiske retningslinjer er fulgt, i forbindelse med plantningen
      samt den relaterede Co2 kompensation.
    </Typography>
    <Typography gutterBottom>
      Plan Vivo blev etableret i 1996 og er den ældste standard for
      certificering af klimabeskyttelsesprojekter inden for jordbrugssektoren.
    </Typography>
    <Typography gutterBottom>
      Genplantning og skovudnyttelsesprojekter modtager først PlanVivos
      godkendelse, når de opfylder særligt høje krav. Bla. skal projekterne
      baseres og organiseres lokalt, så det gavner lokale landbrugsfamilier, og
      samtidigt er med til at bekæmpe skovrydning og fattigdom. Det er disse
      kvaliteter, er med til at gøre Plan Vivo til en af de mest troværdige og
      stærkeste standarder i verden.
    </Typography>
    <Button
      onClick={() => (window.location.href = "https://www.planvivo.org/")}
      variant="contained"
      color="primary"
      className="faq-btn"
    >
      Læs mere om Plan Vivo her
    </Button>
  </>
);

const AnswerSix = () => (
  <Typography>
    Et fundamentalt princip i EatCo2 konceptet er at vi, IKKE køber
    klimakreditter i eksisterende grønne projekter. Vi laver vores egne, hvilket
    er en filosofi der bygger på at vi skal gavne miljøet med nye friske tiltag,
    frem for at købe os til andres grønne samvittighed. Dvs. alle Co2
    kompenseringer bliver foretaget ved at plante nye træer og ikke købt fra en
    gammel skov.
  </Typography>
);

const AnswerFive = () => (
  <>
    <Typography gutterBottom>
      Vores beregninger er lavet ud fra en gennemsnitlig CO2 udledning på et
      måltid. Dette betyder i teorien at nogle måltider vil kompensere
      væsentligt mere CO2 end de udleder, ligesom andre i få tilfælde
      kompenserer for mindre.{" "}
    </Typography>
    <Button variant="outlined" component={Link} to="/beregning">
      Læs mere her
    </Button>
  </>
);

const AnswerPris = () => (
  <>
    <Typography gutterBottom>
      Vi har besluttet os for at der skal være så få udgifter som muligt
      forbundet med at blive en del af EatCo2 netværket. Det er en beslutning vi
      har taget, fordi vi ikke mener at økonomiske forhold, skal stå i vejen for
      bæredygtigheden.
    </Typography>
    <Typography gutterBottom>
      Selv om at vi meget gerne ville kunne gøre det gratis, er der desværre
      nogle uundgåelige udgifter involverede når vi starter en ny restaurant op,
      men prisen er sat så lavt at de fleste kan være med.
    </Typography>
    <Typography gutterBottom>
      Når du træder ind i netværket, beder vi dig om en engangsbetaling på 2.500
      kr.+ moms, der går ubeskåret til at dække vores drift udgifter.
    </Typography>
    <Typography gutterBottom>
      Mange restauranter vælger også at foretage en initiel CO2-kompenserer, når
      de træder ind i netværket, men der bestemmer du naturligvis selv
    </Typography>
    <Button variant="outlined" component={Link} to="/klima">
      Du kan læse mere her
    </Button>
  </>
);

export const faqs = [
  {
    question: "GreenProfit",
    answer: <AnswerOne />,
    img:
      "https://images.pexels.com/photos/1131458/pexels-photo-1131458.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    question: "CO2-certifikat",
    answer: <AnswerTwo />,
    icon: <ImageOutlined style={{ marginLeft: 5 }} />,
    img: "",
  },
  {
    question: "Beregningsformularen",
    answer: <AnswerFive />,
    img: faq6,
  },
  {
    question: "Kvalitetssikring",
    answer: <AnswerFour />,
  },
  {
    question: "Prisen på bæredygtighed",
    answer: <AnswerPris />,
  },
  {
    question: "Hvorfor vi ikke planter i Danmark",
    answer: <AnswerThree />,
    img: planterTraeer,
  },
  {
    question: "Green-wash eller positiv miljøpåvirkning",
    answer: <AnswerSix />,
  },
];
