import {
  Box,
  Button,
  Fab,
  Fade,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import headerBund from "../assets/icons/header_bund.png";
import home_redskabet from "../assets/icons/home_redskabet.png";
import gaflen from "../assets/icons/vgh.png";
import Bubble from "../components/Bubble";
import Spacer from "../components/Layout/Spacer";
import TwoColumnGrid from "../components/Layout/TwoColumnGrid";
import Overgang from "../components/Overgang";
import Steps from "../components/Steps";
import useTranslation from "../hooks/useT";
import { useEffect } from "react";
import { useMainContext } from "../context/Context";
import headerPic from "../assets/icons/headerpic_jvifyz.png";
import newLogo from "../assets/comingsoon/Aktiv 1.svg";
import newVideo from "../assets/comingsoon/ECO logo animationZoomUp2.mp4";

export default function Home(props) {
  const history = useHistory();

  const { setSelectedLanguage } = useMainContext();
  const t = useTranslation();
  const [show, setShow] = useState(false);
  const [video, setVideo] = useState(newVideo);

  useEffect(() => {
    console.log("useEffect Home!");
    if (window.location.host.endsWith(".com")) {
      console.log("updated");
      setSelectedLanguage("en");
    }
  }, [setSelectedLanguage]);

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#035d62",
      }}
    >
      <video
        muted={true}
        src={video}
        alt="video"
        autoPlay={true}
        onEnded={() => {
          console.log("ended");
          setShow(true);
          setVideo("");
        }}
        style={{ width: "100%", height: "100%" }}
      />

      <Fade appear={show} in={show}>
        <Paper
          style={{
            position: "absolute",
            background: "white",
            padding: 30,
            borderRadius: 15,
            bottom: 40,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={newLogo}
            alt="logo"
            style={{ marginBottom: 30, maxWidth: 300 }}
          />
          <Typography
            variant="h4"
            gutterBottom
            style={{ fontWeight: "bold", color: "#035d62" }}
          >
            {t.look}
          </Typography>
          <Typography>EatCO2 ApS | info@eatco2.com | 70 55 55 80</Typography>
        </Paper>
      </Fade>
    </div>
  );

  return (
    <div>
      <div className="HomeImg">
        <img src={headerPic} alt="" />
        <Typography
          variant="h3"
          gutterBottom
          style={{ paddingBottom: 10, color: "var(--primary)" }}
        >
          {t.homeTitle}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          className="extraLarge"
          onClick={() => history.push("/network")}
          style={{ borderWidth: 3 }}
        >
          {t.homeBtn}
        </Button>
      </div>
      <div className="container">
        <div style={{ position: "relative" }}>
          <div
            style={{
              position: "absolute",
              top: -25,
              right: 200,
              transform: "rotate(180deg)",
              height: 150,
              zIndex: -1,
            }}
          >
            <Bubble variant={2} />
          </div>
        </div>
        <Spacer value={4} />
        <Grid container>
          <Grid item sm={8} xs={12}>
            <Typography gutterBottom variant="h4">
              {t.homeH4}
            </Typography>
            <Typography gutterBottom variant="h6">
              {t.homeH6}
            </Typography>
          </Grid>
        </Grid>
        <TwoColumnGrid alignItems="flex-start">
          <div>
            <Typography gutterBottom>{t.homeP}</Typography>
            <Typography className="semi-bold text--primary" gutterBottom>
              {t.homeP_bold}
            </Typography>
            <Typography gutterBottom>{t.homeP2}</Typography>
            <Typography className="semi-bold text--primary" gutterBottom>
              {t.homeP3}
            </Typography>
          </div>
          <Box display="flex" justifyContent="space-around">
            <img src={gaflen} height={350} alt="" />
          </Box>
        </TwoColumnGrid>
      </div>
      <Spacer value={5} />
      <Overgang>
        <Typography variant="h4">{t.homeOvergang}</Typography>
      </Overgang>
      <Spacer value={5} />
      <div className="container">
        <TwoColumnGrid alignItems="center">
          <div className="text--center">
            <img className="big-img" src={home_redskabet} height={350} alt="" />
          </div>
          <div>
            <Typography variant="h4" gutterBottom>
              {t.homeH4_2}
            </Typography>
            <Typography variant="h6" gutterBottom>
              {t.homeRedskabetSubHeader}
            </Typography>
            <Typography gutterBottom>{t.homeP4}</Typography>
            <Typography gutterBottom>{t.homeP5}</Typography>
            <Typography gutterBottom>{t.homeP6}</Typography>
            <Typography gutterBottom>{t.homeP7}</Typography>
            <Typography gutterBottom className="semi-bold text--primary">
              {t.homeP8}
            </Typography>
            <Button
              component={Link}
              to="/beregning"
              color="primary"
              variant="outlined"
            >
              {t.readMore}
            </Button>
          </div>
        </TwoColumnGrid>
      </div>
      <Spacer value={5} />
      <div className="container">
        <Typography variant="h4" gutterBottom>
          {t.dagsOrden}
        </Typography>
        <Typography variant="h6" gutterBottom>
          {t.do1}
        </Typography>
        <Typography gutterBottom>{t.do2}</Typography>
        <Typography className="semi-bold text--primary" gutterBottom>
          {t.do3}
        </Typography>
        <Typography
          className="semi-bold text--primary"
          gutterBottom
          style={{ fontStyle: "italic" }}
        >
          {t.do4}
          <span>
            <a href="mailto:info@eatco2.com" style={{ color: "blue" }}>
              info@eatco2.com
            </a>
          </span>
        </Typography>
      </div>
      <Spacer value={5} />
      <div className="container m-auto">
        <Spacer value={3} />
        <Box display="flex" alignItems="center">
          <Typography variant="h4">{t.sådanGørDu}</Typography>
          <Fab
            onClick={() => {
              if (!show) {
                window.scrollBy({ top: 200, behavior: "smooth" });
                setShow(true);
              } else {
                setShow(false);
              }
            }}
            color="primary"
            className={!show ? "pulse" : ""}
            style={{ marginLeft: 25 }}
          >
            {show ? <Remove /> : <Add />}
          </Fab>
        </Box>
      </div>
      {show ? (
        <div className="container fade">
          <Spacer value={5} />
          <Steps />
          <Spacer value={5} />
          <Typography variant="h4" gutterBottom>
            {t.initiativTitle}
          </Typography>
          <Typography variant="h6" gutterBottom>
            {t.initiativP}
          </Typography>
          <Typography gutterBottom>{t.initiativP2}</Typography>
          <Typography gutterBottom>{t.initiativP3}</Typography>
          <Spacer />
        </div>
      ) : null}
      <div className="container">
        <Spacer value={5} />
        <Button
          variant="contained"
          color="primary"
          size="large"
          component={Link}
          to="/network"
        >
          {t.netTitle}
        </Button>
      </div>
      <Spacer value={5} />
      <img
        src={headerBund}
        className="hide-me"
        height={400}
        alt=""
        style={{ position: "absolute", bottom: -250, right: 100 }}
      />
    </div>
  );
}
