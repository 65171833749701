import React from "react";
import { Typography } from "@material-ui/core";
import { renderRest } from "../pages/Handelsbetingelser";
import Spacer from "../components/Layout/Spacer";
import useTranslation from "../hooks/useT";

export const KøbsBetingelser = () => {
  const t = useTranslation();

  const data = [
    {
      paragraphs: [t.hand1, t.hand2],
    },
    {
      title: t.hand3,
      paragraphs: [t.hand4, t.hand5, t.hand6],
    },
    {
      title: t.hand7,
      paragraphs: [t.hand8, t.hand9, t.hand10, t.hand11, t.hand12],
    },
    {
      title: t.hand13,
      paragraphs: [t.hand14, t.hand15, t.hand16],
    },
    {
      title: t.hand17,
      paragraphs: [t.hand18, t.hand19],
    },
    {
      title: t.hand20,
      paragraphs: [t.hand21, t.hand22],
    },
    {
      title: t.hand23,
      paragraphs: [t.hand24, t.hand25, t.hand26],
    },
  ];

  return (
    <div>
      <Typography variant="h3" gutterBottom paragraph>
        {t.hand}
      </Typography>
      {renderRest(data)}
      <Typography gutterBottom className="bold">
        EatCO2 ApS
      </Typography>
      <Typography gutterBottom>CVR: 38659928</Typography>
      <Typography gutterBottom>Taastrupgaardsvej 20</Typography>
      <Typography gutterBottom>2630 Taastrup, Danmark</Typography>
      <Spacer />
      <Typography gutterBottom>Email: info@eatco2.com</Typography>
      <Typography gutterBottom>{t.tlf}: +45 70 55 55 80</Typography>
    </div>
  );
};
