import { Switch, Route } from "react-router-dom";
import React from "react";
import Home from "../pages/Home";
import Food from "../pages/Food";
import Strategi from "../pages/Strategi";
import Klima from "../pages/Klima";
import { useLocation } from "react-router-dom";
import { useTransition, animated } from "react-spring";
import Privacy from "../pages/Privacy";
import Handelsbetingelser from "../pages/Handelsbetingelser";
import Manifest from "../pages/Manifest";
import ScrollToTop from "../components/ScrollToTop";
import Beregning from "../pages/Beregning";
import Customer from "../pages/Customer";
import Faq from "../pages/Faq";
import Network from "../pages/Network";
import OmOs from "../pages/OmOs";

const Navigation = () => {
  const location = useLocation();
  const transitions = useTransition(location, (location) => location.pathname, {
    from: { opacity: 0, transform: "translate3d(100%,0,0)" },
    enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
    leave: {
      opacity: 0,
      transform: "translate3d(-50%,0,0)",
      position: "absolute",
    },
  });

  return transitions.map(({ item, props, key }) => (
    <div key={key} style={props} className="Navigation">
      <ScrollToTop />
      <Switch location={item}>
        <Route path="/" exact>
          <Home />
        </Route>
        <Route path="/klima">
          <Klima />
        </Route>
        <Route path="/food">
          <Food />
        </Route>
        <Route path="/strategy">
          <Strategi />
        </Route>
        <Route path="/beregning">
          <Beregning />
        </Route>
        <Route path="/b2c">
          <Customer />
        </Route>
        <Route path="/privacy">
          <Privacy />
        </Route>
        <Route path="/conditions">
          <Handelsbetingelser />
        </Route>
        <Route path="/manifest">
          <Manifest />
        </Route>
        <Route path="/network">
          <Network />
        </Route>
        <Route path="/faq">
          <Faq />
        </Route>
        <Route path="/about-us">
          <OmOs />
        </Route>
      </Switch>
    </div>
  ));
};

export default Navigation;
