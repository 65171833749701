import React from "react";
import { Typography, List, ListItem, ListItemText } from "@material-ui/core";
import TwoColumnGrid from "../components/Layout/TwoColumnGrid";
import Spacer from "../components/Layout/Spacer";

import img1 from "../assets/CommuniTree_Limay_Nicaragua/CommuniTree Limay_Nicaragua_7-kopi.jpg";
import Bubble from "../components/Bubble";

export default function Manifest() {
  return (
    <div className="container gap">
      <Typography variant="h3" gutterBottom className="text--secondary">
        Manifest
      </Typography>
      <Typography variant="h5" gutterBottom>
        Vi har en ambition om at drive og udvikle EatCo2 på en måde, der skaber
        vækst, har fokus på bæredygtighed, og fremmer innovation i den tredje
        verden.
      </Typography>

      <Spacer value={5} />
      <TwoColumnGrid alignItems="flex-start">
        <Typography gutterBottom>
          EatCo2s kernevirksomhed består af at plante hurtigt voksende træer i
          troperne, hvilket resulterer i en ekstrem effektiv Co2 kompensering.
          Vores vigtigste fokusområde er hotel & restaurantionsbranchen.
        </Typography>
        <Typography gutterBottom>
          Vi opfordrer gastronomer til at deltage i initiativet, og bruge deres
          spisested som katalysator i kampen for klimaet, ved at opfordrer deres
          gæster til at udligne Co2 aftrykket i maden.
        </Typography>
      </TwoColumnGrid>
      <Spacer value={5} />
      <img
        src={img1}
        alt=""
        height={300}
        width={"100%"}
        style={{ objectFit: "cover" }}
      />
      <Spacer value={5} />
      <Typography variant="h5" gutterBottom>
        Sociale forhold
      </Typography>
      <Typography gutterBottom>
        Alle aspekter af vores forretning er baseret på respekten for
        menneskerettigheder og på medarbejderes ret til meningsfuldt arbejde
        under sikre forhold.{" "}
      </Typography>
      <Typography gutterBottom>
        Vi stræber efter at udvikle en organisation, der bidrager med nye ideer
        og perspektiver på vores vej til at skabe et mere bæredygtigt samfund.{" "}
      </Typography>
      <Typography gutterBottom>
        Vores værdier er baseret på åbenhed og ærlighed, og på nationale og
        internationale regler og retningslinjer.{" "}
      </Typography>
      <Spacer value={5} />
      <Typography variant="h5" gutterBottom>
        Medarbejder relationer
      </Typography>
      <Typography gutterBottom>
        EatCo2 er en sund arbejdsplads der bygger på positiv energi, i et
        produktivt, sikkert og uhøjtideligt arbejdsmiljø.
      </Typography>
      <Spacer value={5} />
      <Typography variant="h5" gutterBottom>
        EatCo2s værdier:
      </Typography>
      <TwoColumnGrid>
        <List>
          <ListItem>
            <ListItemText primary="• At have en proaktiv tilgang til miljøudfordringer" />
          </ListItem>
          <ListItem>
            <ListItemText primary="• At støtte initiativer der øger opmærksomheden på miljøet" />
          </ListItem>
          <ListItem>
            <ListItemText primary="• At fremme udviklingen af miljøvenlige initiativer i den tredje verden" />
          </ListItem>
          <ListItem>
            <ListItemText primary="• At fremme bæredygtigt iværksætteri og nytænkning" />
          </ListItem>
        </List>
        <div className="text--center">
          <Bubble variant={9} />
        </div>
      </TwoColumnGrid>
      <Spacer value={5} />
      <Typography variant="h5" gutterBottom>
        Ledernes perspektiv
      </Typography>
      <TwoColumnGrid>
        <Typography>
          Klimaforandringer og miljøudfordringer er den absolut største
          prioritet hos EatCo2. Vi har sat grundlæggende mål for hvordan vi kan
          bidrage, ved at integrere bæredygtighed i vores kerneaktiviteter og
          gøre det afgørende for vores langsigtede mission.{" "}
        </Typography>
        <Typography>
          Vi stræber efter at være frontløber i udviklingen af et
          mikro-offset-system der kan tilpasses alle fødevare, og som hvis
          organiseres korrekt, kan bidrage enormt til reducere CO2-emissioner på
          verdensplan.
        </Typography>
      </TwoColumnGrid>
    </div>
  );
}
