import React, { useState } from "react";
import text from "../utils/text";
import { useContext } from "react";

export const Context = React.createContext();

export function Provider({ children }) {
  const [snackbar, setSnackbar] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState("da");

  const value = {
    t: text,
    selectedLanguage,
    setSelectedLanguage,
    snackbar,
    setSnackbar,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export const useMainContext = () => useContext(Context);
