import React, { useState } from "react";
import {
  Paper,
  Box,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@material-ui/core";
import Spacer from "./Layout/Spacer";
import { useMainContext } from "../context/Context";
import useTranslation from "../hooks/useT";
import { api } from "../utils/api";

export default function KontaktForm() {
  const t = useTranslation();

  const [formFields, setFormFields] = useState({
    type: t.option1,
    company: "",
    contact: "",
    email: "",
    phone: "",
  });

  const { setSnackbar } = useMainContext();

  const handleChange = (event) => {
    setFormFields({
      ...formFields,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = () => {
    api(formFields, "Bliv en del af netværket");
    setSnackbar(t.snackNetwork);
  };

  return (
    <>
      <Paper style={{ position: "relative" }}>
        <Box display="flex" flexDirection="column" padding={3}>
          <FormControl>
            <InputLabel>Type</InputLabel>
            <Select value={formFields.type} name="type" onChange={handleChange}>
              <MenuItem value={t.option1}>{t.option1}</MenuItem>
              <MenuItem value={t.option2}>{t.option2}</MenuItem>
              <MenuItem value={t.option3}>{t.option3}</MenuItem>
            </Select>
          </FormControl>
          <TextField
            InputLabelProps={{ shrink: true }}
            label={t.formVirksomhed}
            value={formFields.company}
            margin="normal"
            name="company"
            onChange={handleChange}
          ></TextField>
          <TextField
            InputLabelProps={{ shrink: true }}
            label={t.formKontakt}
            value={formFields.contact}
            margin="normal"
            name="contact"
            onChange={handleChange}
          ></TextField>
          <TextField
            InputLabelProps={{ shrink: true }}
            label={t.email}
            value={formFields.email}
            type="email"
            margin="normal"
            name="email"
            onChange={handleChange}
          ></TextField>
          <TextField
            InputLabelProps={{ shrink: true }}
            label={t.tlf}
            value={formFields.phone}
            type="number"
            margin="normal"
            name="phone"
            onChange={handleChange}
          ></TextField>
          <Spacer />
          <Button
            variant="contained"
            color="primary"
            disabled={
              !formFields.phone ||
              !formFields.company ||
              !formFields.email ||
              !formFields.contact
            }
            onClick={handleSubmit}
          >
            {t.kontaktMig}
          </Button>
          <Typography variant="caption" style={{ marginTop: 10 }}>
            {t.formDisclaimer}
          </Typography>
        </Box>
      </Paper>
    </>
  );
}
