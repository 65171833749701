import React from "react";
import BulletPoints from "../components/BulletPoints";
import { Typography, Box, Button, Grid } from "@material-ui/core";
import TwoColumnGrid from "../components/Layout/TwoColumnGrid";
import KontaktForm from "../components/KontaktForm";
import { Link } from "react-router-dom";
import Spacer from "../components/Layout/Spacer";
import useTranslation from "../hooks/useT";
import plante from "../assets/icons/klimamærke.png";
import plante1 from "../assets/icons/klimamærke1.png";
import plante2 from "../assets/icons/klimamærke2.png";
import certifkat1 from "../assets/certifikat/1.jpg";
import certifkat2 from "../assets/certifikat/2.jpg";

export default function Network() {
  const t = useTranslation();

  return (
    <div className="gap extra-margin">
      <div className="container">
        <Typography variant="h3" gutterBottom className="text--secondary">
          {t.networkTitle}
        </Typography>
        <Typography variant="h6">{t.net1}</Typography>
        <Typography variant="h6">{t.net2}</Typography>
      </div>
      <Spacer value={5} />
      <div className="container">
        <TwoColumnGrid alignItems="flex-start">
          <div>
            <Typography gutterBottom>
              <span className="text--primary semi-bold">{t.net3}</span> {t.net4}
            </Typography>
            <Spacer />
            <Typography gutterBottom className="text--primary semi-bold">
              {t.net5}
            </Typography>
            <Spacer value={3} />
            <BulletPoints t={t} />
          </div>
          <div style={{ position: "relative", top: -30 }}>
            <div className="klima-maerke-abs" style={{ marginBottom: 20 }}>
              <img src={plante} alt="" height={170} width={170} />
              <img
                src={plante1}
                alt=""
                height={170}
                width={170}
                style={{
                  position: "absolute",
                  left: 100,
                  zIndex: -2,
                }}
              />
              <img
                src={plante2}
                alt=""
                height={170}
                width={170}
                style={{
                  left: 300,
                  zIndex: -1,
                  position: "absolute",
                }}
              />
            </div>
            <KontaktForm />
          </div>
        </TwoColumnGrid>
      </div>
      <Spacer value={5} />
      <div className="container">
        <Spacer value={5} />
        <TwoColumnGrid>
          <div>
            <Typography gutterBottom variant="h4">
              {t.kliCertifikat}
            </Typography>
            <Typography gutterBottom variant="h6">
              {t.kliP4}
            </Typography>
            <Typography gutterBottom>{t.kliP5}</Typography>
          </div>
          <Grid container spacing={2} style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={6} className="text--center">
              <img
                src={certifkat1}
                height={350}
                alt=""
                style={{ objectFit: "contain" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} className="text--center">
              <img
                src={certifkat2}
                height={350}
                alt=""
                style={{ objectFit: "contain" }}
              />
            </Grid>
          </Grid>
        </TwoColumnGrid>
        {/*
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h3" paragraph gutterBottom>
            {t.eatco2idag}
          </Typography>
          <Typography variant="h6" gutterBottom paragraph>
            {t.bestil}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/klima"
            size="large"
          >
            {t.bestilBtn}
          </Button>
        </Box> */}
        <Spacer value={5} />
      </div>
    </div>
  );
}
