import React from "react";
import Spacer from "../components/Layout/Spacer";
import { Typography, List, ListItem, ListItemText } from "@material-ui/core";
import TwoColumnGrid from "../components/Layout/TwoColumnGrid";

const content = [
  {
    title: "Data beskyttelse",
    paragraphs: [
      "Funktioner leveret af udbydere af sociale medier som Facebook, Twitter, Linkedin, osv. Kan være integreret på vores hjemmesider, og som automatisk overfører brugerrelaterede data til den relevante tredjepartsudbyder, hvis du samtidig er logget ind på udbyderens sociale medieplatform. EatCO2 har ikke adgang til eller påvirker denne dataoverførsel.",
      'EatCO2 bruger Google Analytics, en webanalysetjeneste leveret af Google Inc. ("Google"). Google Analytics bruger såkaldte “cookies”, som er tekstfiler, der er gemt på din computer, og som tillader analyse af din brug af hjemmesiden. Oplysningerne, der genereres af cookien om din brug af dette websted.',
      "Google kan også overføre disse oplysninger til tredjepart, hvis dette er lovpålagt, eller hvis tredjeparter behandler disse data på vegne af Google. Du kan forhindre installation af cookies ved at indstille din browsersoftware i overensstemmelse hermed; dog vil vi gerne påpege, at du i dette tilfælde muligvis ikke er i stand til at bruge alle EatCO2-funktioner i deres helhed. Ved at bruge dette websted accepterer du, at Google behandler data om dig på den måde og til de formål, der er beskrevet ovenfor.",
    ],
  },
  {
    title: "Databehandling",
    paragraphs: [
      "EatCO2 ApS behandler personoplysninger på vegne af den Dataansvarlige, (Kunden), hvorfor Parterne er enige om følgende:",
      "Databehandleraftalen har til formål at sikre, at Parterne overholder den ved Databehandleraftalens indgåelse gældende persondataretlige regulering, dvs.:",
      "Persondataloven (lov 2000-05-31 nr. 429 med senere ændringer).",
      "Persondataforordningen (Europaparlamentets og Rådets forordning, EU) 2016/679 af 27. april 2016), PR. D. 25. maj 2018.",
      "Databehandleren, (EatCO2), bemyndiges til at foretage behandling af personoplysninger på den Dataansvarliges vegne på vilkårene fastsat heri.",
      "Databehandleren må alene behandle personoplysninger efter dokumenteret instruks fra den Dataansvarlige (”Instruks”).",
      "Instruksen kan til enhver tid ændres eller konkretiseres nærmere af den Dataansvarlige.",
      "Sådanne ændringer foretages i henhold til den mellem Parterne aftalte ændringshåndteringsproces.",
      "Databehandleraftalen gælder indtil kundeforholdet mellem Databehandleren og Dataansvarlige ophører.",
      "Databehandleren skal sikre, at medarbejdere, der behandler personoplysninger for Databehandleren, har forpligtet sig til fortrolighed eller er underlagt en passende lovbestemt tavshedspligt.",
      "Databehandleren skal sikre, at adgangen til personoplysningerne begrænses til de medarbejdere, for hvem det er nødvendigt at behandle personoplysninger for at kunne opfylde Databehandlerens forpligtelser over for den Dataansvarlige.",
      "Databehandleren stiller efter anmodning alle oplysninger, der er nødvendige for at påvise overholdelse af kravene i Databehandleraftalen. En sådan anmodning besvares inden rimelig tid.",
      "Databehandleren underretter omgående den Dataansvarlige, hvis en instruks efter Databehandlerens mening er i strid med databeskyttelseslovgivningen eller databeskyttelsesbestemmelser i anden EU-ret eller nationale ret.",
      "Databehandleren underretter uden unødig forsinkelse den dataansvarlige hvis Databehandleren bliver opmærksom på, at der er sket brud på persondatasikkerheden.",
      "Underretningen skal indeholde de faktiske omstændigheder ved bruddet på persondatasikkerheden, dets virkninger og de trufne og planlagte afhjælpende foranstaltninger.",
      "På den Dataansvarliges anmodning, bistår Databehandleren så vidt muligt den Dataansvarlige ved hjælp af passende tekniske og organisatoriske foranstaltninger, med opfyldelse af den Dataansvarliges forpligtelse til at besvare anmodninger om udøvelse af de registreredes rettigheder.",
      "Under hensyntagen til behandlingens karakter og de oplysninger, der er tilgængelige for Databehandleren bistår Databehandleren den Dataansvarlige med at sikre overholdelse af forpligtelserne vedrørende den Dataansvarliges:",
      () => (
        <List>
          <ListItem disableGutters>
            <ListItemText primary="• Behandlingssikkerhed" />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText primary="• Anmeldelse af brud på persondatasikkerheden til tilsynsmyndigheder" />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText primary="• Underretning om brud på persondatasikkerheden til registrerede" />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText primary="• Konsekvensanalyser vedrørende databeskyttelse, og" />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText primary="• Forudgående høringer" />
          </ListItem>
        </List>
      ),
      "Databehandleren må kun gøre brug af en tredjepart til behandlingen af personoplysninger for den Dataansvarlige (”Underdatabehandler”) i det omfang det anses som nødvendigt.",
      "Databehandleren og Underdatabehandleren skal indgå en skriftlig aftale, som pålægger Underdatabehandleren de samme databeskyttelsesforpligtelser, som påhviler Databehandleren (herunder i medfør af disse handelsbetingelser.",
      "Underdatabehandleren handler herudover ligeledes alene på Instruks fra den Dataansvarlige.",
      "Databehandleren er direkte ansvarlig for Underdatabehandlerens behandling af personoplysninger på samme vis, som var behandling foretaget af Databehandleren selv.",
      "Databehandleren kan behandle personoplysninger udenfor Instruksen i tilfælde, hvor det kræves af EU-retten eller national ret, som Databehandleren er underlagt.",
      "Ved behandling af personoplysninger udenfor Instruksen skal Databehandleren underrette den Dataansvarlige om årsagen hertil. Underretningen skal ske, inden behandlingen foretages og skal indeholde en henvisning til de retlige krav, der ligger til grund for behandlingen.",
      "Underretning skal ikke ske, hvis underretning vil være i strid med EU-retten eller den nationale ret.",
      "Databehandleren har krav på betaling efter medgået tid samt Databehandlerens øvrige omkostninger herved, for de ydelser der udføres efter Databehandleraftalen på den Dataansvarliges anmodning. Ydelserne kan omfatte, men er ikke begrænset til, ændringer af instruksen, assistance ved anmeldelse af brud på persondatasikkerheden, udlevering og sletning af oplysninger, bistand ved audit, bistand ved ophør, samarbejde med tilsynsmyndigheder og hjælp til efterlevelse af anmodninger fra registrerede.",
      "Databehandleren har krav på betaling efter medgået tid samt Databehandlerens øvrige omkostninger herved, for de ydelser der udføres efter Databehandleraftalen som følger af ændringer i den Dataansvarliges forhold. Ydelserne kan omfatte, men er ikke begrænset til, bistand til ændringer der følger af nye risikovurderinger og konsekvensanalyser samt ændringer nødvendiggjort af ændringer i lovgivningen.",
      "Vederlaget opgøres efter Leverandørens gældende timesatser.",
      "Databehandleren og dennes Underdatabehandlere skal tilbagelevere alle personoplysninger, som Databehandleren har behandlet under denne Databehandleraftale, til den Dataansvarlige ved Databehandleraftalens ophør, i det omfang den Dataansvarlige ikke allerede er i besiddelse af personoplysningerne. Databehandleren er herefter forpligtet til at slette alle personoplysninger fra den Dataansvarlige.",
      "Den Dataansvarlige kan anmode om fornøden dokumentation for, at dette er sket.",
      "Ved spørgsmål til databehandling hos novodana.dk kan der rettes henvendelse til:",
      "DPO, (Data Protection Officer).",
      "Thomas Larsen, DPO databeskyttelse@eatco2.dk",
      "Du kan til enhver tid tilbagekalde dit samtykke ved at klikke på afmeld",
    ],
  },
  {
    title: "Ansvar",
    paragraphs: [
      "EatCO2 fraskriver sig ethvert ansvar for skader på grund af faktorer uden for EatCO2s kontrol så som dårligt vejr, naturkatastrofer, politisk uro, krig, terrorisme, mangel på råvarer, eller fejl i den tekniske infrastruktur, hvilket resulterer i at EatCO2 er ikke i stand til at opfylde sine kontraktlige forpligtelser som et resultat.",
      "EatCO2 beregner vores CO2 kompenseringer udelukkende på grundlag af data fra vores plantepartnere samt andre. Alle CO2 kompenseringer sker under opsyn af PlanVivo standarden og anses for at være pålidelige, men EatCo2 er ikke ansvarlig for unøjagtige, fejlagtige eller ufuldstændige data eller for beregningsfejl.",
      "EatCO2, dens partnere, investorer, personale og associerede virksomheder påtager sig intet ansvar for tab, direkte eller indirekte, i form af omkostninger, krav, skader eller udgifter uanset art, pådraget i forbindelse med brugen af denne hjemmeside og dens indhold.",
      "EatCO2 kan give adgang til tredjemands hjemmeside via relevante links eller præsentere produkter, serviceydelser og information fra tredjemand. EatCO2 har ikke gennemgået, og kan ikke gøres ansvarlig for, indholdet af tredjemands hjemmesider, materiale og produkter. ",
    ],
  },
  {
    title: "Kontraktændringer",
    paragraphs: [
      "EatCo2 kan ændre vores handelsbetingelser til enhver tid. Den aktuelle version offentliggøres på hjemmesiden og / eller sendes til kunden. Efterfølgende ændringer af handelsbetingelserne vil blive en integreret del af kontrakten, hvis kunden ikke skriftligt gør indsigelse mod dem inden 30 dage efter, at handelsbetingelserne er blevet offentliggjort online og / eller sendt til kunden.",
    ],
  },
  {
    title: "Effektivitet",
    paragraphs: [
      "Hvis individuelle bestemmelser i disse generelle vilkår og betingelser er ineffektive, påvirker dette ikke effektiviteten af de resterende bestemmelser eller kontrakter indgået på grundlag heraf. Den ineffektive bestemmelse erstattes af en effektiv bestemmelse, der nærmest tilnærmer hensigten og formålet med den oprindelige bestemmelse.",
    ],
  },
  {
    title: "Gældende lov og jurisdiktion",
    paragraphs: [
      "Et samarbejde med EatCO2 er reguleret af Dansk lov.",
      "Medmindre andet er aftalt, skal domstolene i Danmark have jurisdiktion over enhver tvist, der opstår under eller i forbindelse med handelsbetingelserne eller samarbejdet.",
      "Udgivet af:",
      "EatCO2 ApS",
      "Taastrupgaardsvej 20",
      "2630 Taastrup, Danmark",
      "\n",
      "E-mail: info@eatco2.com",
      "Telefon: +45 70 55 55 80",
      "CVR: 38659928",
    ],
  },
];

export const renderRest = (content) => {
  const stuff = content.map((el) => {
    let finalRender = [];
    finalRender.push(
      <Typography gutterBottom variant="h5">
        {el.title}
      </Typography>
    );
    for (const p of el.paragraphs) {
      if (typeof p == "string") {
        finalRender.push(<Typography paragraph>{p}</Typography>);
      } else {
        finalRender.push(p());
      }
    }
    finalRender.push(<Spacer value={3} />);
    return finalRender;
  });
  return stuff;
};

export default function Handelsbetingelser() {
  return (
    <div className="container gap">
      <Spacer />
      <TwoColumnGrid>
        <div>
          <Typography variant="h3">
            Vilkår og betingelser for EatCo2 ApS
          </Typography>
          <Typography variant="h5">Mad med samvittighed</Typography>
        </div>
        <div className="height-placeholder" />
      </TwoColumnGrid>
      <Typography variant="caption">
        Dette afsnit beskriver de betingelser, der er knyttet til alle tjenester
        leveret af EatCO2 Aps, (CVR 38659928).
      </Typography>
      <Spacer value={5} />
      <Typography variant="h5" gutterBottom>
        Generelt
      </Typography>
      <Typography>
        EatCo2 ApS er et dansk selskab, der er engageret i verdensomspændende
        klimabeskyttelsesprojekter. EatCo2 udøver sin mission inden for følgende
        områder:
      </Typography>
      <List disablePadding>
        <ListItem disableGutters>
          <ListItemText primary="• Projekter vedrørende klimabeskyttelse" />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText primary="• Rådgivning om bæredygtighed" />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText primary="• Projectudvikling" />
        </ListItem>
      </List>
      <Spacer value={3} />
      <Typography variant="h5" gutterBottom>
        Betalinger
      </Typography>
      <Typography gutterBottom>
        Køb af CO2-kompenseringer er underlagt moms, medmindre kunden er
        momsfritaget. Med mindre andet er aftalt, forfalder fakturaer 7
        kalenderdage efter fakturering. Alle bankgebyrer, forbundet med
        overførelsen, bæres af kunden og må ikke sænke det samlede fakturerede
        beløb. Forsinkede betalinger er underlagt danske rentelovsbestemmelser.
      </Typography>
      <Spacer value={3} />
      <Typography variant="h5" gutterBottom>
        Kundedata
      </Typography>
      <Typography gutterBottom>
        EatCO2 behandler data modtaget fra kunden, fortroligt i overensstemmelse
        med GDPR-lovgivningen. EatCO2 kan dog bruge data til statistiske,
        videnskabelige eller reklamemæssige formål. Hvis sådanne data
        offentliggøres, anonymiserer vi kundenavnet og offentligøre det først
        ved udtrykkelige samtykke fra kunden.
      </Typography>
      <Typography gutterBottom>
        EatCO2 ejer ophavsretten til alle statistiske rapporter genereret af
        EatCO2, på baggrund af data vi indsamler.
      </Typography>
      <Spacer value={3} />
      <Typography variant="h5" gutterBottom>
        Opsigelse af samarbejde
      </Typography>
      <Typography gutterBottom>
        Medmindre andet er aftalt i kontrakten, kan både kunden og EatCO2 opsige
        kontrakten pr. Slutningen af hvert kalender måned med et måneds
        forudgående varsel.
      </Typography>
      <Typography gutterBottom>
        En kontrakt kan opsiges med øjeblikkelig virkning uden forudgående
        varsel hvis kundens handlinger er i konflikt med værdierne og målene for
        EatCO2, eller kundens adfærd kan skade EatCo2s omdømme.
      </Typography>
      <Typography gutterBottom>
        Opsigelsen er kun effektiv, hvis den foretages i skriftlig form via
        e-mail Kunden skal sende opsigelser til følgende e-mailadresse:
        co2@eatco2.dk
      </Typography>
      <Spacer value={3} />
      <Typography variant="h5" gutterBottom>
        Brug af etiketter, navne og logoer
      </Typography>
      <Typography gutterBottom>
        Kun når du indgår et samarbejde med EatCO2 har du ret til at gøre det
        kendt for tredjepart og bruge EatCO2-mærket on og offline i
        markedsføringsøjemed.
      </Typography>
      <Typography gutterBottom>
        Du må ikke foretage nogen ændring på EatCO2-mærket eller materiale i alt
        almindelighed, og er opmærksom på at EatCO2 vil håndhæve sine
        rettigheder mht. copyright og varemærkebeskyttelse til det fuldeste
        omfang af loven.
      </Typography>
      <Typography gutterBottom>
        Desuden giver EatCO2 en ikke-eksklusiv, ikke-overførbar ret til at du
        som kunde må bruge EatCO2s navn og logo til offentliggørelsesformål.
      </Typography>
      <Typography gutterBottom>
        EatCo2 kan navngive kunden ved henvisning på vores hjemmeside eller i
        andre medier, medmindre parterne har aftalt andet.
      </Typography>
      <Typography gutterBottom>
        Retten til at bruge EatCO2-mærket ophører i tilfælde af skade til
        EatCO2s omdømme, eller hvis det vurderes at kundens handlinger er i
        modstrid med værdierne og målene for EatCO2.
      </Typography>
      <Typography gutterBottom>
        EatCO2 kan vælge at give kunden en periode til at rette op på
        misforholdet.
      </Typography>
      <Typography gutterBottom>
        Ved afslutning af samarbejdet må materialer og rettigheder (især logoer
        og mærker), ikke længere anvendes og skal om nødvendigt returneres.
        Dette inkluderer ikke at citerer samarbejdet til referenceformål.
      </Typography>
      <Spacer value={3} />
      {renderRest(content)}
    </div>
  );
}
