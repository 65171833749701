import React, { useState } from "react";
import {
  Typography,
  Grid,
  Paper,
  TextField,
  Box,
  Divider,
  FormControlLabel,
  Checkbox,
  Button,
  Dialog,
  DialogContent,
  Slider,
  DialogActions,
} from "@material-ui/core";
import Spacer from "../components/Layout/Spacer";
import { Link } from "react-router-dom";
import plante from "../assets/icons/klimamærke.png";
import plante1 from "../assets/icons/klimamærke1.png";
import plante2 from "../assets/icons/klimamærke2.png";
import { FilterDrama, Money, MoneyOff, ShoppingCart } from "@material-ui/icons";
import { KøbsBetingelser } from "../components/Købsbetingelser";
import TwoColumnGrid from "../components/Layout/TwoColumnGrid";
import useTranslation from "../hooks/useT";
import { useMainContext } from "../context/Context";
import certifkat1 from "../assets/certifikat/1.jpg";
import certifkat2 from "../assets/certifikat/2.jpg";
import { api } from "../utils/api";

import fi from "../assets/flags/fi.svg";
import dk from "../assets/flags/dk.svg";
import swe from "../assets/flags/swe.svg";
import eur from "../assets/flags/eur.svg";
import no from "../assets/flags/no.svg";

const s = (langCode, currency, number) => {
  return new Intl.NumberFormat(langCode, {
    style: "currency",
    currency,
  }).format(number);
};

export default function Klima() {
  const [isOpen, setIsOpen] = useState(false);
  const [air, setAir] = useState(500);
  const [godkendt, setGodkendt] = useState(false);
  const [formFields, setFormFields] = useState({
    company: "",
    cvr: "",
    phone: "",
    email: "",
    address: "",
    postal: "",
    city: "",
  });

  const [currency, setCurrency] = useState("dk");

  const t = useTranslation();
  const { setSnackbar } = useMainContext();

  const handleChange = (event) => {
    setFormFields({
      ...formFields,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = () => {
    setSnackbar(t.snackNetwork);
    const body = { ...formFields, climateStamps: air };
    api(body, "Klima mærker");
  };

  let stamps = air;
  const dkk = air * 10;
  const str__ = s("da-DK", "DKK", dkk).lastIndexOf("kr");
  let amount = "DKK " + s("da-DK", "DKK", dkk).substr(0, str__);
  const str__2 = s("da-DK", "DKK", dkk * 0.25).lastIndexOf("kr");
  let tax = s("da-DK", "DKK", dkk * 0.25).substr(0, str__2);
  const str__3 = s("da-DK", "DKK", dkk * 1.25).lastIndexOf("kr");
  let total = "DKK " + s("da-DK", "DKK", dkk * 1.25).substr(0, str__3);

  if (currency === "eur") {
    const eur = air * 10 * 0.14;
    amount = s("en-GB", "EUR", eur);
    tax = s("en-GB", "EUR", eur * 0.25);
    total = s("en-GB", "EUR", eur * 1.25);
  } else if (currency === "swe") {
    const swe = air * 10 * 1.5;
    const string_ = s("sv-SE", "SEK", swe);
    amount = "SEK " + string_.substr(0, string_.lastIndexOf("kr"));
    tax = 0;
    total =
      "SEK " + s("sv-SE", "SEK", swe).substr(0, string_.lastIndexOf("kr"));
  } else if (currency === "no") {
    const nor = air * 10 * 1.5;
    amount = s("no-NO", "NOK", nor);
    tax = 0;
    total = s("no-NO", "NOK", nor);
  } else if (currency === "fi") {
    const eur = air * 10 * 0.14;
    amount = s("en-GB", "EUR", eur);
    tax = 0;
    total = s("en-GB", "EUR", eur);
  }

  return (
    <div className="container gap">
      <TwoColumnGrid>
        <div>
          <Typography variant="h3" gutterBottom className="text--secondary">
            {t.kliTitle}
          </Typography>
          <Typography variant="h5" gutterBottom>
            {t.kliSubTitle}
          </Typography>
        </div>
        <div className="height--placeholder" />
      </TwoColumnGrid>
      <Spacer value={5} />
      <Grid container spacing={4}>
        <Grid item sm={6} xs={12}>
          <div className="klima-maerke-abs">
            <img src={plante} alt="" height={170} width={170} />
            <img
              src={plante1}
              alt=""
              height={170}
              width={170}
              style={{
                position: "absolute",
                left: 100,
                zIndex: -2,
              }}
            />
            <img
              src={plante2}
              alt=""
              height={170}
              width={170}
              style={{
                left: 300,
                zIndex: -1,
                position: "absolute",
              }}
            />
          </div>
          <Spacer value={5} />
          <Typography gutterBottom>{t.kliP}</Typography>
          <Typography gutterBottom>{t.kliP2}</Typography>
          <Typography gutterBottom>{t.kliP3}</Typography>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Paper>
            <Box display="flex" flexDirection="column" style={{ padding: 20 }}>
              <Box display="flex">
                <TextField
                  label={t.kliInput}
                  margin="dense"
                  fullWidth
                  style={{ marginRight: 10 }}
                  InputLabelProps={{ shrink: true }}
                  name="company"
                  onChange={handleChange}
                />
                <TextField
                  type="number"
                  label="CVR"
                  margin="dense"
                  InputLabelProps={{ shrink: true }}
                  name="cvr"
                  onChange={handleChange}
                />
              </Box>
              <Box display="flex">
                <TextField
                  label={t.kliInput2}
                  type="number"
                  margin="dense"
                  style={{ marginRight: 10 }}
                  InputLabelProps={{ shrink: true }}
                  name="phone"
                  onChange={handleChange}
                />
                <TextField
                  label="Email"
                  type="email"
                  margin="dense"
                  fullWidth
                  name="email"
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
              <TextField
                label={t.kliInput3}
                margin="dense"
                name="address"
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
              />
              <Box display="flex">
                <TextField
                  label={t.kliInput4}
                  type="number"
                  margin="dense"
                  name="postal"
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                  style={{ marginRight: 10 }}
                />
                <TextField
                  label={t.kliInput5}
                  type="text"
                  margin="dense"
                  fullWidth
                  name="city"
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
            </Box>
            <Spacer />
            <Divider />
            <Box display="flex" flexDirection="column" style={{ padding: 20 }}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="caption">{t.kliCap}</Typography>
                <div className="klima-flags">
                  <img onClick={() => setCurrency("dk")} src={dk} alt="" />
                  <img onClick={() => setCurrency("no")} src={no} alt="" />
                  <img onClick={() => setCurrency("fi")} src={fi} alt="" />
                  <img onClick={() => setCurrency("swe")} src={swe} alt="" />
                  <img onClick={() => setCurrency("eur")} src={eur} alt="" />
                </div>
              </Box>
              <Slider
                valueLabelDisplay="auto"
                max={5000}
                min={500}
                step={500}
                value={air}
                onChange={(e, value) => setAir(value)}
              ></Slider>
              <Grid
                container
                justify="space-between"
                className="slider-container"
              >
                <Grid item>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    fontSize="12px"
                  >
                    <FilterDrama /> {`${t.kliTitle}: ${stamps}`}
                  </Box>
                </Grid>
                <Grid item>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    fontSize="12px"
                  >
                    <Money /> {`${t.kliPrice}: ${amount}`}
                  </Box>
                </Grid>
                <Grid item>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    fontSize="12px"
                  >
                    <MoneyOff />
                    {`${t.kliTax}: ${tax}`}
                  </Box>
                </Grid>
                <Grid item>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    fontSize="12px"
                  >
                    <ShoppingCart /> {`Total: ${total}`}
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <Box display="flex" flexDirection="column" style={{ padding: 20 }}>
              <Box display="flex" justifyContent="space-between">
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setIsOpen(true)}
                >
                  {t.kliReadRules}
                </Button>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={godkendt}
                      onChange={(e) => setGodkendt(e.currentTarget.checked)}
                      color="primary"
                    />
                  }
                  label={t.kliAcceptRules}
                />
              </Box>
              <Spacer />
              <Button
                variant="contained"
                color="primary"
                disabled={
                  !godkendt ||
                  !air ||
                  !formFields.address ||
                  !formFields.city ||
                  !formFields.cvr ||
                  !formFields.phone ||
                  !formFields.postal ||
                  !formFields.company
                }
                onClick={handleSubmit}
              >
                {t.kliBtn2}
              </Button>
              <Dialog
                open={isOpen}
                onClose={() => setIsOpen(false)}
                maxWidth="sm"
              >
                <DialogContent>
                  <KøbsBetingelser />
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setIsOpen(false)}>
                    {t.dialogClose}
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Spacer value={5} />
      <div>
        <TwoColumnGrid>
          <div>
            <Typography gutterBottom variant="h4">
              {t.kliCertifikat}
            </Typography>
            <Typography gutterBottom variant="h6">
              {t.kliP4}
            </Typography>
            <Typography gutterBottom>{t.kliP5}</Typography>
            <Button
              variant="outlined"
              color="primary"
              component={Link}
              to="/network"
              size="large"
            >
              {t.kliBtn}
            </Button>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} className="text--center">
              <img
                src={certifkat1}
                height={350}
                alt=""
                style={{ objectFit: "contain" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} className="text--center">
              <img
                src={certifkat2}
                height={350}
                alt=""
                style={{ objectFit: "contain" }}
              />
            </Grid>
          </Grid>
        </TwoColumnGrid>
      </div>
      <Spacer />
    </div>
  );
}
