import React, { useState } from "react";
import { MuiThemeProvider, CssBaseline, TextField } from "@material-ui/core";
import theme from "./utils/theme";
import { BrowserRouter } from "react-router-dom";
import Navigation from "./utils/router";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import CallMe from "./components/CallMe";
import { Provider } from "./context/Context";

function App() {
  const [live, setLive] = useState(true);
  const [secret, setSecret] = useState("");

  if (!live && process.env.NODE_ENV === "production") {
    return (
      <TextField
        value={secret}
        onChange={(e) => {
          if (e.currentTarget.value === "eatandplant") {
            setLive(true);
          }
          setSecret(e.currentTarget.value);
        }}
      ></TextField>
    );
  }

  return (
    <BrowserRouter>
      <Provider>
        <MuiThemeProvider theme={theme}>
          {/* <Navbar /> */}
          <Navigation />
          {/*<Footer />
          <CallMe />
         */}
        </MuiThemeProvider>
        <CssBaseline />
      </Provider>
    </BrowserRouter>
  );
}

export default App;
