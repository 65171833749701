import React from "react";
import { Grid } from "@material-ui/core";

export default function TwoColumnGrid({ children, alignItems }) {
  return (
    <Grid container spacing={3} alignItems={alignItems || "center"}>
      <Grid item sm={6} xs={12}>
        {children[0]}
      </Grid>
      <Grid item sm={6} xs={12}>
        {children[1]}
      </Grid>
    </Grid>
  );
}
